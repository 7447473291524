import { Box } from '@mui/material';
import React from 'react';
import { IPhoneHalfPreview } from '../../../../../../../../common/components/IPhonePreview/IPhoneHalfPreview';
import { MessageBox } from '../../../../../../../../common/components/IPhonePreview/IPhoneSMSPreview';
import RewardCardPreview from '../../../../../../../../common/components/RewardCardPreview/RewardCardPreview';
import {
  LocalMessageVariables,
  replaceMessageBodyWithLocalVariables,
} from '../../../../../../../../common/components/SMS/SMS.helper';
import { useClientType } from '../../../../../../../../services/hooks/useClientType';
import { useTypedSelector } from '../../../../../../../../store';
import { useStyles } from './CustomizeRewardMessagePreview.styles';
import { getIsNullReward } from '../../../../../../../../services/utilities';

export const RewardImageSectionPreview: React.FC = () => {
  const styles = useStyles();
  const {
    campaignType,
    smsRewardMessage,
    howToRewardCreators: { cardColor, contact, rewardHeader, contactMethod },
  } = useTypedSelector((state) => state.createCampaign);
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const isNullReward = getIsNullReward(campaignType);
  const {
    venue: { logo },
  } = useTypedSelector((state) => state.venue);

  const { rewardTitle } = currentCampaign || {};

  const { isHealthCare } = useClientType();

  const rewardMessage = replaceMessageBodyWithLocalVariables(
    smsRewardMessage || '',
    isHealthCare,
    true,
  );

  return (
    <Box className={styles.container}>
      <IPhoneHalfPreview height={900}>
        {!isNullReward && (
          <Box className={styles.rewardContainer}>
            <RewardCardPreview
              cardColor={cardColor}
              contact={contact}
              rewardTitle={rewardTitle || ''}
              rewardHeader={rewardHeader}
              logo={logo}
              contactMethod={contactMethod}
              scaleFactor={0.6}
            />
          </Box>
        )}
        <MessageBox
          width="280px"
          fontSize={16}
          message={rewardMessage}
          boldSubstrings={Object.values(LocalMessageVariables)}
          showStartBlock={false}
        />
      </IPhoneHalfPreview>
    </Box>
  );
};
