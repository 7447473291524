import { Box } from '@mui/material';
import React, { Dispatch, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { VideoSharedScreenIcon } from '../../../../common/assets/newDesign/WebAppIcons';
import CircleCouponIcon from '../../../../common/assets/newDesign/WebAppIcons/CouponCircleIcon';
import { SignUpFieldsIcon } from '../../../../common/assets/newDesign/WebAppIcons/SignUpFieldsIcon';
import Carousel, { CarouselItem } from '../../../../common/components/Carousel/Carousel';
import { IconWrapper, ITab } from '../../../../common/components/Carousel/Carousel.helper';
import { IPhoneSignUpPreview } from '../../../../common/components/IPhonePreview/IPhoneSignUpPreview';
import { useTabs } from '../../../../common/components/Tabs/useTabs';
import { PersonalizationSubroutes, WebsiteRoutes } from '../../../../common/constants/routes';
import { useAppDispatch, useTypedSelector } from '../../../../store';

import { useStyles } from '../../QuickStart.styles';
import { getMe } from '../../../../store/slices/meSlice';
import { RewardStrings } from '../../../../common/localization/en';
import { formatStringWithPattern, getOnlyNumbers } from '../../../../services/utilities';
import { IPhoneVerification } from '../../../../common/components/IPhonePreview/IPhoneVerification';
import { ConfigStateCard } from '../../../webapp/BrandingStyle/BrandingStyle';

import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../../../common/constants/events/common';
import RewardCardPreview from '../../../../common/components/RewardCardPreview/RewardCardPreview';
import {
  DEFAULT_REWARD_HEADER,
  DEFAULT_REWARD_NAME,
} from '../../../NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import CreateVideoPreview from '../../../../common/components/previews/CreateVideoPreview/CreateVideoPreview';
import { VideoAppTheme } from '../../../../api/models/venue';
import { CoverType } from '../../../../common/components/previews/CreateVideoPreview/CreateVideoPreview.helper';
import { FormikProps } from 'formik';
import { AnimationStyleOptions } from '../../../../common/components/AnimationStyleSelect/AnimationStyleSelect';

interface Tab extends ITab {
  content?: React.ReactNode;
}

interface PreviewSectionProps {
  logoImage?: string;
  backgroundImage?: string;
  brandColor?: string;
  setConfigCard?: Dispatch<React.SetStateAction<ConfigStateCard>>;
  configCard?: ConfigStateCard;
  title?: string;
  subtitle?: string;
  onTabChange?: (tabIndex: number) => void;
  theme: VideoAppTheme;
  selectedAnimation?: string;
  launchScreenType: 'name' | 'logo';
  formik?: FormikProps<any>;
  handleAnimationStyleChange?: (style: AnimationStyleOptions) => void;
}

export const PreviewSection: React.FC<PreviewSectionProps> = ({
  logoImage,
  backgroundImage,
  brandColor,
  configCard,
  setConfigCard,
  title,
  subtitle,
  onTabChange,
  theme,
  selectedAnimation,
  launchScreenType,
  formik,
  handleAnimationStyleChange,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const me = useTypedSelector((state) => state.me);
  const pathname = location.pathname;

  useEffect(() => {
    dispatch(getMe({ id: me.id }));
  }, [me.id, dispatch]);

  const isLogoSection = pathname.includes(PersonalizationSubroutes.UploadLogo);
  const isBackgroundImageSection = pathname.includes(
    PersonalizationSubroutes.UploadBackgroundImage,
  );
  const isWebAppSection =
    pathname.includes(PersonalizationSubroutes.SelectBrandColor) ||
    pathname.endsWith(WebsiteRoutes.QuickStartPersonalize);

  const isShowAnimationStyleSelect = isBackgroundImageSection;

  const isDashboardBrandingStyle = pathname.includes(WebsiteRoutes.BrandingStyleTab);
  const isDashboardSignUp = pathname.includes(WebsiteRoutes.SignUpTab);

  const formattedPhone = useMemo(() => {
    if (me.phoneNumber) {
      return formatStringWithPattern(getOnlyNumbers(me.phoneNumber), '+# (###) ###-####');
    }
  }, [me.phoneNumber]);

  const brandingStyleTab: Tab = {
    text: 'Create Clip',
    icon: (
      <IconWrapper>
        <SignUpFieldsIcon height="40px" />
      </IconWrapper>
    ),
    content: (
      <CreateVideoPreview
        brandColor={brandColor}
        image={backgroundImage || ''}
        logo={logoImage}
        theme={theme}
        selectedAnimation={selectedAnimation}
        reveal={selectedAnimation?.split('_')[1] as 'branding' | 'splash'}
        cover={selectedAnimation?.split('_')[0] as CoverType}
        launchScreenType={launchScreenType}
        handleAnimationStyleChange={handleAnimationStyleChange}
      />
    ),
  };

  // const webAppTab: Tab = {
  //   text: 'Video App',
  //   icon: (
  //     <IconWrapper>
  //       <WelcomeScreenIcon height={40} />
  //     </IconWrapper>
  //   ),
  //   content: (
  //     <EducationCardsPreview
  //       aligmentText={configCard?.aligmentText}
  //       image={backgroundImage ?? ''}
  //       brandColor={brandColor ?? 'black'}
  //       logo={logoImage}
  //       title={title}
  //       subtitle={subtitle}
  //     />
  //   ),
  // };

  const videoPlaybackTab: Tab = {
    text: 'Clip Playback',
    icon: (
      <IconWrapper>
        <VideoSharedScreenIcon height={40} />
      </IconWrapper>
    ),
    content: (
      <CreateVideoPreview
        brandColor={brandColor}
        image={backgroundImage || ''}
        logo={logoImage}
        theme={theme}
        selectedAnimation={selectedAnimation}
        reveal={selectedAnimation?.split('_')[1] as 'branding' | 'splash'}
        cover={selectedAnimation?.split('_')[0] as CoverType}
        buttonText={'Check us out'}
        launchScreenType={launchScreenType}
        handleAnimationStyleChange={handleAnimationStyleChange}
      />
    ),
  };

  const signUpTab: Tab = {
    text: 'Creator App',
    icon: (
      <IconWrapper>
        <SignUpFieldsIcon height="40px" />
      </IconWrapper>
    ),
    content: (
      <IPhoneSignUpPreview
        phone={formattedPhone || '+1 (760) 840 1402'}
        name={me.name || 'James Bently'}
        email={me.email || 'Jbently@gmail.com'}
        nameRequired
        brandColor={brandColor ?? 'black'}
        backgroundImage={backgroundImage}
        logoImage={logoImage}
      />
    ),
  };

  const verificationTab: Tab = {
    text: 'Verification',
    icon: (
      <IconWrapper>
        <SignUpFieldsIcon height="40px" />
      </IconWrapper>
    ),
    content: (
      <IPhoneVerification
        phone={formattedPhone || '+1 (760) 840 1402'}
        name={me.name || 'James Bently'}
        email={me.email || 'Jbently@gmail.com'}
        brandColor={brandColor ?? 'black'}
        logoImage={logoImage}
        backgroundImage={backgroundImage}
      />
    ),
  };

  const couponTab: Tab = {
    text: RewardStrings.Coupon,
    icon: (
      <IconWrapper>
        <CircleCouponIcon height="40px" />
      </IconWrapper>
    ),
    content: (
      <Box sx={{ transform: 'scale(1.3)' }}>
        <RewardCardPreview
          contact={null}
          cardColor={brandColor}
          rewardTitle={DEFAULT_REWARD_NAME}
          rewardHeader={DEFAULT_REWARD_HEADER}
          logo={logoImage || ''}
        />
      </Box>
    ),
  };

  const splashLoader: Tab = {
    text: 'Opening Animation',
    icon: (
      <IconWrapper>
        <SignUpFieldsIcon height="40px" />
      </IconWrapper>
    ),
    content: (
      <CreateVideoPreview
        brandColor={brandColor}
        image={backgroundImage || ''}
        logo={logoImage}
        theme={theme}
        selectedAnimation={selectedAnimation}
        reveal={selectedAnimation?.split('_')[1] as 'branding' | 'splash'}
        cover={selectedAnimation?.split('_')[0] as CoverType}
        launchScreenType={launchScreenType}
        handleAnimationStyleChange={handleAnimationStyleChange}
        showAnimationStyleSelect={isShowAnimationStyleSelect}
      />
    ),
  };

  const logoTabs = [splashLoader, videoPlaybackTab, couponTab];
  const backgroundImageSectionTabs = [splashLoader];
  const webAppTabs = [splashLoader, videoPlaybackTab];
  const dashboardBrandingStyleTabs = [brandingStyleTab];

  const dashboardWebAppTabs = [signUpTab, verificationTab];

  const getCarouselTabs = () => {
    if (isLogoSection) return logoTabs;
    if (isBackgroundImageSection) return backgroundImageSectionTabs;
    if (isWebAppSection) return webAppTabs;
    if (isDashboardBrandingStyle) return dashboardBrandingStyleTabs;
    if (isDashboardSignUp) return dashboardWebAppTabs;
    return [];
  };

  const tabs = getCarouselTabs();

  const { activeTab, setActiveTab, resetActiveTab } = useTabs(tabs.length);

  useEffect(() => {
    resetActiveTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleTabClick = (tab: ITab) => {
    const tabName = tab.text.toLowerCase().replaceAll(' ', '_');
    const eventName = `${tabName}_${CommonEventNames.carousel_tab_click}`;
    trackEvent(eventName);
  };

  if (isDashboardBrandingStyle) {
    return (
      <CreateVideoPreview
        brandColor={brandColor}
        image={backgroundImage || ''}
        logo={logoImage}
        theme={theme}
        selectedAnimation={selectedAnimation}
        reveal={selectedAnimation?.split('_')[1] as 'branding' | 'splash'}
        cover={selectedAnimation?.split('_')[0] as CoverType}
        launchScreenType={launchScreenType}
        handleAnimationStyleChange={handleAnimationStyleChange}
      />
    );
  }
  return (
    <Box className={classes.previewContainer}>
      <Carousel
        tabs={tabs}
        activeTabIndex={activeTab}
        onActiveTabUpdate={(newTab) => {
          setActiveTab(newTab);
          onTabChange?.(newTab);
        }}
        onAutoScroll={undefined}
        handleTabClick={handleTabClick}
        hideDivider={false}
      >
        {tabs.map((tab) => (
          <CarouselItem key={tab.text}>{tab.content}</CarouselItem>
        ))}
      </Carousel>

      {/* <CreateVideoPreview
          brandColor={brandColor}
          image={backgroundImage || ''}
          logo={logoImage}
          theme={theme}
          selectedAnimation={selectedAnimation}
          reveal={selectedAnimation?.split('_')[1] as 'branding' | 'splash'}
          cover={selectedAnimation?.split('_')[0] as CoverType}
          launchScreenType={launchScreenType}
          handleAnimationStyleChange={handleAnimationStyleChange}
          showAnimationStyleSelect={isShowAnimationStyleSelect}
        /> */}
    </Box>
  );
};
