import { Box, Typography, useTheme } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { PlansEventNames } from '../../../common/constants/events/plansEvents';

interface TileListProps {
  title?: string;
  canGoToSignUp: boolean;
  redirectToSignUp: () => void;
}

export const TileList: React.FC<TileListProps> = ({
  title,
  redirectToSignUp,
  canGoToSignUp,
  children,
}) => {
  const theme = useTheme();
  const { trackEvent } = useTrackEvent();

  return (
    <Box>
      <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, min-content)" width="100%">
        {title && (
          <Typography fontSize="20px" color={theme.palette.common.deepDark} mb="8px">
            {title}
          </Typography>
        )}
        <span></span>
        <span></span>
        <RoundedButton
          title="Activate Account Form"
          endIcon={<ChevronRightIcon style={{ fill: 'white' }} />}
          disabled={!canGoToSignUp}
          borderWidth={canGoToSignUp ? '4px' : '0px'}
          bdColor={canGoToSignUp ? '#FDB927' : undefined}
          onClick={() => {
            redirectToSignUp();

            trackEvent(PlansEventNames.goToSignUpFormButtonClicked);
          }}
          style={{ width: '100%' }}
        />
        {children}
      </Box>
    </Box>
  );
};
