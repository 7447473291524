import { useLocation } from 'react-router-dom';
import { EnterpriseFlow } from './Enterprise';
import { StripeFlowWrapper } from './Stripe';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// const APIresponse = {
//   account: {
//     id: '77879b16-8afb-45e7-aa31-18925f077b8e',
//     name: 'Account Signup PWD R Token',
//     subdomain: 'lwaoi',
//     clientType: 'MARKETING',
//     logo: '',
//     teamName: 'Account Signup PWD R Token',
//     launchedAt: null,
//     websiteUrl: 'https://',
//     industry: null,
//     planId: '717980a5-e97a-498a-b240-46d225f5bb48',
//     refId: null,
//     billingAccountId: '83fbbc18-4d8f-4837-914c-73ba0ec047d7',
//     businessAddress: ' ,  ',
//     active: true,
//     deactivatedAt: null,
//     deactivatedBy: null,
//     billable: true,
//     setupCompleted: true,
//     tutorialCompleted: null,
//     createdAt: '2024-10-23T11:14:27.562252490Z',
//     updatedAt: '2024-10-23T11:14:27.562252631Z',
//     createdBy: null,
//     lastModifiedBy: null,
//   },
//   token: '3tii4IirG3Thhi9ioqw7288gGNFn6fHJ',
// };

export const QuickSignupSuccess = () => {
  const query = useQuery();

  const isEnterprise = query.get('ie');

  return <>{isEnterprise ? <EnterpriseFlow /> : <StripeFlowWrapper />}</>;
};
