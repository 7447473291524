import { Button, useTheme } from '@mui/material';
import React from 'react';

export interface SelectableTileProps extends React.CSSProperties {
  selected?: boolean;
  onClick?: () => void;
  width?: string;
  height?: string;
  padding?: string;
}

export const SelectableTile: React.FC<SelectableTileProps> = ({
  selected,
  onClick,
  width,
  height,
  padding = '16px',
  children,
  ...cssProperties
}) => {
  const theme = useTheme();
  const borderColor = selected ? '#FDB927' : 'transparent';

  return (
    <Button
      onClick={onClick}
      sx={{
        background: theme.palette.common.white,
        border: `4px solid ${borderColor}`,
        borderRadius: '16px',
        width,
        height,
        padding,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        ...cssProperties,
        justifySelf: 'start',
      }}
    >
      {children}
    </Button>
  );
};
