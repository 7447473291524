import * as Yup from 'yup';
import { ErrorsStrings } from '../../../../../../common/localization/en';
import { isValidPhoneNumber } from 'libphonenumber-js';

const required = ErrorsStrings.FieldIsRequired;

// const ONLY_LETTERS_REGEX = /^([A-Za-z ]{2,48})$/gm;

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, ErrorsStrings.Minlength2characters)
    .max(48, ErrorsStrings.Maxlength48characters)
    .trim('Remove unnecessary spaces')
    .strict(true)
    .required(required),
  /* .matches(ONLY_LETTERS_REGEX, ErrorsStrings.OnlyLetters2) */
  lastName: Yup.string()
    .min(2, ErrorsStrings.Minlength2characters)
    .max(48, ErrorsStrings.Maxlength48characters)
    .trim('Remove unnecessary spaces')
    .strict(true)
    .required(required),
  /* .matches(ONLY_LETTERS_REGEX, ErrorsStrings.OnlyLetters2) */ optedIn: Yup.bool()
    .equals([true], required)
    .typeError(required),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test('is-valid-phone', ErrorsStrings.PhoneIsNotValid, (value) =>
      value ? isValidPhoneNumber(value, 'US') : false,
    ),
});
