import { Box, Grid, Tooltip } from '@mui/material';
import { FC } from 'react';
// import { ClientTypes } from '../../../api/models/common';
// import { useTypedSelector } from '../../../store';
import { VideoDetailsStats } from '../../localization/en';
// import { createVideoData } from './VideosDetailVideoData.helper';
import { useVideosDetailVideoDataStyles } from './VideosDetailVideoData.style';
import { VideosMetricsApiModel } from '../../../api/models/videos';
import { PlayIcon } from '../../assets/newDesign/PlayIcon';
import { CreatorIcon } from '../../assets/newDesign/CreatorsIcon';
interface IVideosDetailVideoDataProps {
  views: number;
  ctaClicks: number;
  shares: number;
  svMetrics: VideosMetricsApiModel | null;
}

export const VideosDetailVideoData: FC<IVideosDetailVideoDataProps> = ({
  views,
  ctaClicks,
  shares,
  svMetrics,
}) => {
  /*   const conversionRate = useMemo(
    () => insertAt(getRoundedValue(!views ? 0 : (ctaClicks / views) * 100) || 0, '%', 'end'),
    [views, ctaClicks],
  ); */
  // const { clientType } = useTypedSelector((state) => state.venue.venue);
  // const isTopgolf = clientType === ClientTypes.TOPGOLF;
  // const videoData = createVideoData(views, shares, ctaClicks);
  const classes = useVideosDetailVideoDataStyles();

  const metrics = svMetrics || { plays: 0, uniqueVisitors: 0, svs: [] };

  console.log('svMetrics', svMetrics);

  return (
    <Box className={classes.VideoDataContainer}>
      <Grid container spacing={2} sx={{ mb: '20px', ml: '0px', mt: '0px' }}>
        {/* <Box key={share.label} >
              <Tooltip
                title={share.label}
                followCursor
                placement="top"
                arrow
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              >
                <Box className={classes.IconVideoDataContainer}>{share.icon}</Box>
              </Tooltip>
              <Box className={classes.ShareInfoContainer}>
                <Typography className={classes.ShareInfoValue}>{share.value}</Typography>
              </Box>
            </Box> */}
        <Grid
          container
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ mb: '20px', ml: '0px', mt: '0px' }}
        >
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Tooltip
              title={VideoDetailsStats.plays}
              followCursor
              placement="top"
              arrow
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            >
              <Box className={classes.IconVideoDataContainer}>
                <PlayIcon />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip
              title={VideoDetailsStats.viewers}
              followCursor
              placement="top"
              arrow
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            >
              <Box className={classes.IconVideoDataContainer}>
                <CreatorIcon />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: '2px black solid' }}>
            <p>{VideoDetailsStats.performance}</p>
          </Grid>
          <Grid item xs={3} sx={{ borderBottom: '2px black solid' }}>
            <p style={{ textAlign: 'center' }}>{metrics.plays}</p>
          </Grid>
          <Grid item xs={3} sx={{ borderBottom: '2px black solid' }}>
            <p style={{ textAlign: 'center' }}>{metrics.uniqueVisitors}</p>
          </Grid>

          {metrics.svs.length ? (
            metrics.svs.map((item) => {
              return (
                <>
                  <Grid item xs={6}>
                    <p style={{ fontWeight: '400', fontSize: '14px', color: '#101828' }}>
                      {item.svName}
                    </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#101828',
                      }}
                    >
                      {item.plays}
                    </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#101828',
                      }}
                    >
                      {item.uniqueVisitors}
                    </p>
                  </Grid>
                </>
              );
            })
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
