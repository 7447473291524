import React from 'react';
import { SmsEditor } from '../../integrations/SmsInvite/Sections/AccordionSection/SmsEditor';
import { ErrorsStrings, SmsInviteStrings } from '../../../common/localization/en';
import { useSmsTemplateVariables } from '../../../services/hooks/useSmsTemplateVariables';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { setSmsInviteMessage } from '../../../store/slices/createCampaignSlice';
import { useModal } from '../../../hooks/useModal';
import { SendTestMessageModal } from '../../../common/components/SMS/SendTestMessageModal';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
} from '../../../common/components/SMS/SMS.helper';
import { useSendTestSms } from '../../../services/hooks/useSendTestSms';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { NewCampaignEvents } from '../../../common/constants/events/newCampaignEvents';
import { getIsNullReward, resizeImageForTwilioMMSCommunication } from '../../../services/utilities';

export const CustomizeSMSSectionContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const me = useTypedSelector((store) => store.me);

  const {
    campaignType,
    smsInviteMessage,
    inviteMediaUrl,
    howToRewardCreators: { rewardName },
  } = useTypedSelector((state) => state.createCampaign);
  const isNullReward = getIsNullReward(campaignType)
  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: isNullReward ? [LocalMessageVariables.RewardName] : [],
    rewardName: rewardName,
  });

  const {
    isOpen: isTestMessageModalOpen,
    open: openTestMessageModal,
    close: closeTestMessageModal,
  } = useModal();

  const constructedTestPostMessage = constructMessageFromTemplate(
    smsInviteMessage,
    templateVariables,
    true,
  );
  const sendTestSMS = useSendTestSms();

  return (
    <>
      <SendTestMessageModal
        modalIsOpen={isTestMessageModalOpen}
        onClose={() => {
          trackEvent(NewCampaignEvents.NewRewardCampaignInvitationMessageModalCloseClick);
          closeTestMessageModal();
        }}
        testMessage={constructedTestPostMessage}
        onTestMessageSendClick={async (data) => {
          trackEvent(NewCampaignEvents.NewRewardCampaignInvitationMessageModalSendClick);
          const resizedImageUrl = await resizeImageForTwilioMMSCommunication(inviteMediaUrl);
          const sendData = resizedImageUrl ? { ...data, mediaUrl: resizedImageUrl } : data;
          sendTestSMS(sendData);
        }}
        phoneNumber={me.phoneNumber}
        name={me.name}
      />
      <SmsEditor
        mt="8px"
        title={SmsInviteStrings.EditInvitationSMS}
        description=""
        hideMessageLabel
        message={smsInviteMessage}
        onMessageChange={async (message: string) => {
          dispatch(setSmsInviteMessage(message));
        }}
        templateVariables={templateVariables}
        disabled={false}
        onSendTestClick={() => {
          openTestMessageModal();
          trackEvent(NewCampaignEvents.NewRewardCampaignInvitationMessageSendSampleTestClick);
        }}
        errorText={!smsInviteMessage.trim() ? ErrorsStrings.FieldIsRequired : ''}
        withUploadImageButton={true}
        isMms={!!inviteMediaUrl}
        isCreateNewCampaign
      />
    </>
  );
};
