import { Box, Checkbox, Grid } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ClientTypes } from '../../../api/models/common';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { CommonTable } from '../../../common/components/table/CommonTable';
import {
  CampaignInvitesStrings,
  CommonStrings,
  NoFilterResultsStrings,
  NotFoundPageStrings,
  RewardsPageStrings,
  VenueCreationWizardStrings,
} from '../../../common/localization/en';
import { existingCampaignUsersTableContent } from '../../../services/helpers/tableMappers';
import { useClientType } from '../../../services/hooks/useClientType';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
  getIsNullReward,
} from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  closeCampaignSummaryUserDetails,
  getCampaignCountData,
  getCampaignUsers,
  getCampaignUsersAPICall,
  getCampaignVideos,
  goToSelectedPageExistingCampaignUsersTable,
  openCampaignSummaryUserDetails,
  setCreatorsTableActiveTabIndex,
  setExistingCampaignUsersTableSorting,
  updateCampaignUsersLoading,
  updateExistingCampaignUsersTableSearch,
  updateExistingCampaignUsersTableSize,
} from '../../../store/slices/campaignsSlice';

import { useExistingCampaignUsersTableStyles } from './ExistingCampaignUsersTable.style';
import { approveReward, approveRewardBatch } from '../../../store/slices/userDetailsSlice';
import { useToasts } from 'react-toast-notifications';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import CheckedCheckboxIcon from '../../../common/assets/CheckedCheckboxIcon';
import CheckboxIcon from '../../../common/assets/CheckboxIcon';
import { LoadingButton } from '@mui/lab';
import { CampaignUser } from '../../../api/models/campaigns';
import { EmptyResultsPreview } from '../../../common/components/EmptyResultsPreview/EmptyResultsPreview';
import { defaultPagination } from '../../../common/constants/constants';
import { CreatorDetailsSlideout } from '../../../common/components/CreatorDetailsSlideout/CreatorDetailsSlideout';
import TableFilterSelect, { TabItem } from './TableFilterSelect/TableFilterSelect';

interface Prop {}

export const CampaignUsersTable: FC<Prop> = () => {
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const styles = useExistingCampaignUsersTableStyles({});

  const { trackEvent: handleTrackEvent } = useTrackEvent();
  const { countData } = useTypedSelector((state) => state.campaigns);
  const { clientType } = useTypedSelector((state) => state.venue.venue);

  const { isTrubluClient } = useClientType();
  const { items, totalPages, totalItems, page, size, sort, search, isLoading } = useTypedSelector(
    (state) => state.campaigns.currentCampaignUsers,
  );
  const {
    currentCampaign,
    isCountDataLoading,
    campaignSummaryUserDetails,
    creatorsTableActiveTabIndex,
  } = useTypedSelector((state) => state.campaigns);

  const { campaignUser } = useTypedSelector((state) => state.userDetails);
  const { id: campaignId } = useParams<{ id: string }>();
  const [updateTable, setUpdateTable] = useState(0);
  const currUserIdRef = useRef<string | null>(null);

  const [unfulfilledUsers, setUnfulfilledUsers] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [shouldrefreshTable, setShouldrefreshTable] = useState(false);
  const [showSelectAllCheckBox, setShowSelectAllCheckBox] = useState(false);
  const isIncentiveCampaign = !getIsNullReward(currentCampaign?.campaignType);

  type CreatorTabItem = TabItem & {
    fulfilled: boolean | null;
  };

  useEffect(() => {
    return () => {
      dispatch(updateExistingCampaignUsersTableSearch(''));
    };
  }, [dispatch]);

  const tabsList: CreatorTabItem[] = useMemo(
    () => [
      {
        tabName: isIncentiveCampaign
          ? RewardsPageStrings.Rewarded
          : RewardsPageStrings.Acknowledged,
        usersCount: (creatorsTableActiveTabIndex === 0 && isCountDataLoading
          ? '---'
          : countData.fulfilledUsers || '---'
        ).toString(),
        fulfilled: true,
        disabled: countData.fulfilledUsers === 0,
      },
      {
        tabName: isIncentiveCampaign
          ? RewardsPageStrings.NotRewarded
          : RewardsPageStrings.NotAcknowledged,
        usersCount: (creatorsTableActiveTabIndex === 1 && isCountDataLoading
          ? '---'
          : countData.activeUsers - countData.fulfilledUsers || '---'
        ).toString(),
        fulfilled: false,
        disabled: countData.activeUsers - countData.fulfilledUsers === 0,
      },
    ],
    [
      isIncentiveCampaign,
      creatorsTableActiveTabIndex,
      isCountDataLoading,
      countData.fulfilledUsers,
      countData.activeUsers,
    ],
  );

  useEffect(() => {
    const index = tabsList.findIndex((item) => item.usersCount !== '---');

    dispatch(setCreatorsTableActiveTabIndex(index === -1 ? 0 : index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getCampaignUsers({
        id: campaignId,
        pageable: { page: 0 },
        filter: {
          fulfilled: false,
        },
        hasVideos: true,
      }),
    ).then((res) => {
      //@ts-ignore
      const userIds = (res.payload.items as CampaignUser[])
        .filter((item) => item.phoneNumberVerified)
        .map((item) => item.userId);
      setUnfulfilledUsers(userIds);
      setShouldrefreshTable(true);
    });
  }, [campaignId, dispatch, updateTable]);

  useEffect(() => {
    if (shouldrefreshTable) {
      dispatch(
        getCampaignUsers({
          search,
          id: campaignId,
          pageable: { page, size, sort },
          filter: {
            fulfilled: tabsList[creatorsTableActiveTabIndex].fulfilled,
          },
          hasVideos: true,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignId,
    page,
    size,
    sort,
    search,
    creatorsTableActiveTabIndex,
    updateTable,
    shouldrefreshTable,
  ]);

  useEffect(() => {
    /* update table if user points added or fulfilled status changed from user slideout,
    but prevent update on user slideout open */
    if (currUserIdRef.current !== campaignUser?.userId) {
      currUserIdRef.current = campaignUser?.userId || null;
      return;
    }

    dispatch(
      getCampaignUsers({
        search,
        id: campaignId,
        pageable: { page, size, sort },
        filter: {
          fulfilled: creatorsTableActiveTabIndex === 1 || null,
        },
        hasVideos: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignUser?.points, campaignUser?.fulfilled, campaignUser?.videos]);

  const approveRewardHandler = useCallback(
    async (userId: string) => {
      const response = await dispatch(approveReward({ id: userId, campaignId }));

      if (response.payload) {
        addToast(VenueCreationWizardStrings.RewardApprovedMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        setUpdateTable((state) => state + 1);
        dispatch(
          getCampaignCountData({
            campaignId,
          }),
        );
      } else {
        addToast(NotFoundPageStrings.Description2, { appearance: 'error' });
      }
    },
    [addToast, dispatch, campaignId],
  );

  const onCheckboxToggle = (userId: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedUsers((users) => [...users, userId]);
    } else {
      setSelectedUsers((users) => users.filter((currUserId) => currUserId !== userId));
    }
  };

  const userDataTableContents = existingCampaignUsersTableContent({
    campaign: currentCampaign!,
    items,
    venuType: clientType || ClientTypes.SALES,
    isTrubluClient,
    isInvites: true,
    approveRewardHandler,
    handleTrackEvent,
    isUserSlideoutOpen: campaignSummaryUserDetails.isOpen,
    isLoading,
    selectedUsers,
    onCheckboxToggle,
    hasSelectableUsers: showSelectAllCheckBox,
  });

  const getPredictiveSearchItems = async (search: string) => {
    const result = await getCampaignUsersAPICall({
      search,
      id: campaignId,
      pageable: { page: 0, size: 100, sort: defaultPagination.sortByLastCreated },
      filter: {
        fulfilled: tabsList[creatorsTableActiveTabIndex].fulfilled,
      },
      hasVideos: true,
    });

    const predictiveSearchItems = result.items.map((item) => item.userName || '').filter(Boolean);
    return [...new Set(predictiveSearchItems)];
  };

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }
    dispatch(goToSelectedPageExistingCampaignUsersTable(convertedTargetPage));
  };
  const onSort = (name: string) => {
    dispatch(setExistingCampaignUsersTableSorting(name));
    handleTrackEvent(
      RewardCampaignsEvents.RewardCampaignsActiveCustomersTableSortClick.replace(
        'sort_by_column',
        `${tabsList[creatorsTableActiveTabIndex].tabName}_tab_sort_by_${name}`,
      ).toLowerCase(),
    );
  };

  const onClickRow = (id: string) => {
    dispatch(openCampaignSummaryUserDetails(id));
    handleTrackEvent(RewardCampaignsEvents.RewardCampaginTableRowClick, { selectedUserId: id });
  };
  const changeSize = (size: number) => {
    dispatch(updateExistingCampaignUsersTableSize(size));
  };

  const handleRewardUsers = async () => {
    await dispatch(
      approveRewardBatch({
        campaignId,
        userIds: selectedUsers,
      }),
    );
    setSelectedUsers([]);
    setUpdateTable((state) => state + 1);
    dispatch(getCampaignCountData({ campaignId }));
  };

  const closeUserModal = () => {
    dispatch(closeCampaignSummaryUserDetails());
  };

  const handleCampaignClipUpload = () => {
    if (!currentCampaign) return;

    dispatch(
      getCampaignUsers({
        search,
        id: currentCampaign.id,
        pageable: { page, size, sort },
        filter: {
          fulfilled:
            creatorsTableActiveTabIndex === 1
              ? true
              : creatorsTableActiveTabIndex === 2
              ? false
              : null,
        },
        hasVideos: true,
      }),
    );

    dispatch(
      getCampaignVideos({
        id: currentCampaign.id,
        pageable: {
          page: 0,
          size: 100000,
          sort: ['asc'],
        },
      }),
    );
  };

  useEffect(() => {
    setShowSelectAllCheckBox((prevState) => {
      const newState = unfulfilledUsers.length > 0 && creatorsTableActiveTabIndex === 1;
      return isLoading ? prevState : newState;
    });
  }, [creatorsTableActiveTabIndex, unfulfilledUsers, isLoading]);

  return (
    <Box className={styles.container}>
      <CreatorDetailsSlideout
        userId={campaignSummaryUserDetails.userId}
        isOpen={campaignSummaryUserDetails.isOpen}
        handleClose={closeUserModal}
        campaignId={currentCampaign?.id}
        onUpload={handleCampaignClipUpload}
      />
      <TableFilterSelect
        tabsList={tabsList}
        activeTabIndex={creatorsTableActiveTabIndex}
        setActiveTabIndex={(index) => {
          dispatch(updateCampaignUsersLoading(true));
          dispatch(setCreatorsTableActiveTabIndex(index));
        }}
        handleTrackEvent={handleTrackEvent}
        type="active"
        isIncentiveCampaign={isIncentiveCampaign}
        totalNumber={countData.activeUsers}
      />
      <Box className={styles.tableWrapper}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={styles.topWrapper}
        >
          {showSelectAllCheckBox ? (
            <LoadingButton
              variant="contained"
              className={styles.rewardButton}
              onClick={handleRewardUsers}
              disabled={selectedUsers.length === 0 || isLoading}
            >
              {isIncentiveCampaign
                ? CampaignInvitesStrings.RewardSelected
                : CampaignInvitesStrings.AcknowlegeSelected}
            </LoadingButton>
          ) : (
            <Box />
          )}
          <SearchBar
            entity={'campaigns'}
            updateSearch={updateExistingCampaignUsersTableSearch}
            adaptiveWidth={true}
            disabled={isLoading}
            placeholderText={CommonStrings.SearchByName}
            borderColor={'#F2F4F7'}
            clearSearch={creatorsTableActiveTabIndex}
            getPredictiveSearchItems={getPredictiveSearchItems}
          />
        </Box>
        <Grid
          className={styles.table}
          sx={{
            '& th': {
              '&:nth-child(1) div': {
                paddingLeft: showSelectAllCheckBox ? '30px !important' : '10px !important',
              },
            },
          }}
        >
          {showSelectAllCheckBox && (
            <Checkbox
              className={styles.checkbox}
              checked={selectedUsers.length === unfulfilledUsers.length && selectedUsers.length > 0}
              onChange={(_, checked) => {
                setSelectedUsers(checked ? unfulfilledUsers : []);
              }}
              disabled={isLoading}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckedCheckboxIcon />}
              id="select-all-checkbox"
            />
          )}
          <CommonTable
            content={userDataTableContents}
            selectedRowId={campaignSummaryUserDetails.userId}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={false}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            withSearchBar={true}
            labelRowsPerPage={CampaignInvitesStrings.View}
            roundedNavButtons={false}
            disableTopNav={true}
            emptyTableCustomPreview={
              <EmptyResultsPreview
                title={NoFilterResultsStrings.NoFilterResultsCreatorsTitle}
                subtitle={NoFilterResultsStrings.NoFilterResultsSubTitle}
              />
            }
            showEmptyTableCustomPreview={(search || '').length > 0 && items.length === 0}
          />
        </Grid>
      </Box>
    </Box>
  );
};
