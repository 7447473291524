import { FC } from 'react';

type PlayFillIconProps = {
  color?: string;
  width?: number;
  height?: number;
};

export const PlayFillIcon: FC<PlayFillIconProps> = ({ color, width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.375 2.4165C7.085 2.4165 2.79167 6.70984 2.79167 11.9998C2.79167 17.2898 7.085 21.5832 12.375 21.5832C17.665 21.5832 21.9583 17.2898 21.9583 11.9998C21.9583 6.70984 17.665 2.4165 12.375 2.4165ZM14.9242 13.6578L13.6975 14.3669L12.4708 15.0761C9.5 16.7915 9.59583 16.7915 9.59583 13.4182V11.9998V10.5815C9.59583 7.20817 9.59583 7.20817 12.4708 8.92359L13.6975 9.63275L14.9242 10.3419C17.1667 11.9998 17.1667 11.9998 14.9242 13.6578Z"
        fill={color || '#8B89A0'}
      />
    </svg>
  );
};
