import { FC } from 'react';

type RewardIconProps = {
  color?: string;
  width?: number;
  height?: number;
};
export const RewardIcon: FC<RewardIconProps> = ({ width = 37, height = 37 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.375 27.875H14C12.35 27.875 11 29.225 11 30.875V31.25H9.5C8.885 31.25 8.375 31.76 8.375 32.375C8.375 32.99 8.885 33.5 9.5 33.5H27.5C28.115 33.5 28.625 32.99 28.625 32.375C28.625 31.76 28.115 31.25 27.5 31.25H26V30.875C26 29.225 24.65 27.875 23 27.875H19.625V24.44C19.25 24.485 18.875 24.5 18.5 24.5C18.125 24.5 17.75 24.485 17.375 24.44V27.875Z"
          fill="#8B89A0"
        />
        <path
          d="M28.2199 17.96C29.2099 17.585 30.0799 16.97 30.7699 16.28C32.1649 14.735 33.0799 12.89 33.0799 10.73C33.0799 8.57 31.3849 6.875 29.2249 6.875H28.3849C27.4099 4.88 25.3699 3.5 22.9999 3.5H13.9999C11.6299 3.5 9.58992 4.88 8.61492 6.875H7.77492C5.61492 6.875 3.91992 8.57 3.91992 10.73C3.91992 12.89 4.83492 14.735 6.22992 16.28C6.91992 16.97 7.78992 17.585 8.77992 17.96C10.3399 21.8 14.0899 24.5 18.4999 24.5C22.9099 24.5 26.6599 21.8 28.2199 17.96ZM22.7599 13.175L21.8299 14.315C21.6799 14.48 21.5749 14.81 21.5899 15.035L21.6799 16.505C21.7399 17.405 21.0949 17.87 20.2549 17.54L18.8899 17C18.6799 16.925 18.3199 16.925 18.1099 17L16.7449 17.54C15.9049 17.87 15.2599 17.405 15.3199 16.505L15.4099 15.035C15.4249 14.81 15.3199 14.48 15.1699 14.315L14.2399 13.175C13.6549 12.485 13.9099 11.72 14.7799 11.495L16.2049 11.135C16.4299 11.075 16.6999 10.865 16.8199 10.67L17.6149 9.44C18.1099 8.675 18.8899 8.675 19.3849 9.44L20.1799 10.67C20.2999 10.865 20.5699 11.075 20.7949 11.135L22.2199 11.495C23.0899 11.72 23.3449 12.485 22.7599 13.175Z"
          fill="#8B89A0"
        />
      </svg>
    </>
  );
};
