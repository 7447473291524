import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BillingEndpoints, getApiUrlForId } from '../../api/endpoints';
import {
  BillingPlansApiModel,
  BillingSubscriptionDetails,
  PricingPlanResponse,
} from '../../api/models/billing';
import { httpClient } from '../../services/httpClient/httpClient';

interface BillingInitialState {
  plans: BillingPlansApiModel | null;
  pricingPlan: PricingPlanResponse | null;
  isLoading: boolean;
  isError: boolean;
  redirectUrl: string;
  customerPortalRedirectUrl: string;
  subscriptionDetails: BillingSubscriptionDetails | null;
}

const initialState: BillingInitialState = {
  plans: null,
  pricingPlan: null,
  isLoading: false,
  isError: false,
  redirectUrl: '',
  customerPortalRedirectUrl: '',
  subscriptionDetails: null,
};

export const getBillingConfig = createAsyncThunk(
  'billing/getBillingConfig',
  async (_, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.get<{}, BillingPlansApiModel>({
        url: BillingEndpoints.GetBillingConfig,
        requiresToken: false,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getBillingSubscriptionDetailsSimplified = createAsyncThunk(
  'billing/getBillingSubscriptionDetailsSimplified',
  async (options: { accountId: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.get<{}, BillingPlansApiModel>({
        url: `${BillingEndpoints.GetBillingSubscriptionDetailsSimplified}?accountId=${options.accountId}`,
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface customerPortalSessionRequest {
  payload: {
    successUrl: string;
    accountId: string;
  };
}

interface customerPortalSessionResponse {
  redirectUrl: string;
}

export const getCustomerPortalSession = createAsyncThunk(
  'billing/getCustomerPortalSession',
  async (options: customerPortalSessionRequest, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<any, customerPortalSessionResponse>({
        url: BillingEndpoints.CreateCustomerPortalSession,
        requiresToken: true,
        payload: options.payload,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getPrisingPlan = createAsyncThunk(
  'billing/getBillingPlan',
  async (options: { venueId: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.get<{}, PricingPlanResponse>({
        url: getApiUrlForId(BillingEndpoints.GetPrisingPlan, options.venueId),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface RedirectProps {
  planCode: string | null;
  successUrl: string;
  cancelUrl: string;
}

interface createCheckoutSessionRequest {
  payload: RedirectProps;
  captchaResponse?: string;
}

interface createCheckoutSessionResponse {
  redirectUrl: string;
}

export const createCheckoutSession = createAsyncThunk(
  'billing/createCheckoutSession',
  async (options: createCheckoutSessionRequest, { rejectWithValue }) => {
    try {
      return await httpClient.post<any, createCheckoutSessionResponse>({
        url: BillingEndpoints.CreateCheckoutSession,
        requiresToken: false,
        payload: options.payload,
        params: { captchaResponse: options.captchaResponse },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const BillingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getBillingConfig.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getBillingConfig.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getBillingConfig.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.plans = payload;
      state.isError = false;
    });
    reducersBuilder.addCase(createCheckoutSession.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.redirectUrl = payload.redirectUrl;
      console.log('redirectUrl', payload.redirectUrl);
      state.isError = false;
    });
    reducersBuilder.addCase(getCustomerPortalSession.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.customerPortalRedirectUrl = payload.redirectUrl;
      state.isError = false;
    });
    reducersBuilder.addCase(getCustomerPortalSession.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isError = false;
    });
    reducersBuilder.addCase(getCustomerPortalSession.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    reducersBuilder.addCase(getPrisingPlan.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.pricingPlan = payload;
      state.isError = false;
    });
    reducersBuilder.addCase(getPrisingPlan.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    reducersBuilder.addCase(getPrisingPlan.rejected, (state) => {
      state.isLoading = false;
      state.pricingPlan = null;
      state.isError = true;
    });

    reducersBuilder.addCase(getBillingSubscriptionDetailsSimplified.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getBillingSubscriptionDetailsSimplified.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(
      getBillingSubscriptionDetailsSimplified.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.subscriptionDetails = payload;
        state.isError = false;
      },
    );
  },
});

// export const { resetSuccessStatus } = BillingSlice.actions;
export default BillingSlice.reducer;
