import { Box, Card, Divider, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

import { FTEModalButton } from '../../components/FTEModalButton/FTEModalButton';
import { LayoutLoader } from '../LayoutLoader';

interface TwoSidePageLayoutProps {
  leftSideNode: React.ReactNode;
  leftSideTitle?: React.ReactNode | string;
  leftSideDescription?: string;
  leftSideDescriptionClassName?: string;
  rightSideNode: React.ReactNode;
  rightSideTop?: string;
  loading?: boolean;
  onHelpButtonClick?: () => void;
  leftSideColumnWidths?: number;
  addPaddingForBottomBar?: boolean;
  containerWidth?: string;
  containerHeight?: string;
  pxLeftSide?: number;
  rightSideHeight?: string;
  carousel?: boolean;
  leftSideCentered?: boolean;
  withBottomDivider?: boolean;
  withTopDivider?: boolean;
  withRightSideScroll?: boolean;
}

export const TwoSidePageLayout: React.FC<TwoSidePageLayoutProps> = ({
  leftSideNode,
  leftSideTitle,
  leftSideDescription,
  leftSideDescriptionClassName,
  rightSideNode,
  loading,
  onHelpButtonClick,
  rightSideTop,
  leftSideColumnWidths = 5,
  addPaddingForBottomBar,
  pxLeftSide,
  containerWidth,
  containerHeight,
  rightSideHeight,
  carousel = false,
  leftSideCentered,
  withBottomDivider,
  withTopDivider,
  withRightSideScroll,
}) => {
  const theme = useTheme();

  if (loading) {
    return <LayoutLoader />;
  }

  return (
    <Card
      elevation={0}
      style={{
        width: containerWidth ? containerWidth : '',
        borderRadius: 0,
        flexGrow: 1,
      }}
    >
      <Grid container height={containerHeight ? containerHeight : '100%'}>
        <Grid
          item
          xs={leftSideColumnWidths}
          pt={4}
          px={pxLeftSide ?? 3.5}
          sx={{ display: leftSideCentered ? 'grid' : 'block', overflowY: 'auto' }}
          height={'100%'}
        >
          {withTopDivider && (
            <Divider
              sx={{ marginTop: '108px', height: '1px', alignSelf: 'start' }}
              color="#DCDBE4"
            />
          )}
          {leftSideTitle && (
            <Box
              style={{
                fontSize: '18px',
                fontWeight: '500',
                lineHeight: '28px',
                marginBottom: '6px',
              }}
            >
              {leftSideTitle}
            </Box>
          )}
          {leftSideDescription && (
            <>
              <Typography
                color={theme.palette.common.gray}
                className={leftSideDescriptionClassName}
                fontSize={leftSideTitle ? 11 : 14}
              >
                {leftSideDescription}
              </Typography>

              <Box my={2}>
                <Divider />
              </Box>
            </>
          )}

          <Box
            pb={addPaddingForBottomBar ? '80px' : '0'}
            sx={{
              alignSelf: leftSideCentered ? 'center' : undefined,
              overflowX: 'hidden',
            }}
          >
            {leftSideNode}
          </Box>
          {withBottomDivider && (
            <Divider
              sx={{ marginBottom: '75px', height: '1px', alignSelf: 'end' }}
              color="#DCDBE4"
            />
          )}
        </Grid>

        <Grid
          item
          xs={12 - leftSideColumnWidths}
          pt={4}
          bgcolor="#F5F6F8"
          sx={{
            overflowY: withRightSideScroll ? 'auto' : 'initial',
            height: withRightSideScroll ? '100%' : 'unset',
          }}
        >
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              position="sticky"
              top={rightSideTop ? rightSideTop : '80px'}
              height={rightSideHeight ? rightSideHeight : 'fit-content'}
            >
              {onHelpButtonClick ? (
                carousel ? (
                  <Box
                    style={{
                      position: 'absolute',
                      top: rightSideTop ? rightSideTop : '80px',
                      right: '0',
                      zIndex: 1,
                    }}
                  >
                    <FTEModalButton onClick={onHelpButtonClick} />
                  </Box>
                ) : (
                  <FTEModalButton onClick={onHelpButtonClick} />
                )
              ) : null}
              {rightSideNode}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
