import { FC, useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { useStyles } from './NewCampaignTopBar.styles';
import { Box, Typography } from '@mui/material';
import { RoundedButton } from '../Button/RoundedButton';
import { NewCampaignStrings, RewardStrings } from '../../localization/en';
import { NextIcon } from '../../assets/newDesign/NextIcon';
import { useHistory } from 'react-router-dom';
import { DEFAULT_POINTS_GOAL } from '../../constants/constants';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getPrimaryCampaign,
  patchIncentiveCampaign,
  postIncentiveCampaign,
} from '../../../store/slices/campaignsSlice';
import { WebsiteRoutes } from '../../constants/routes';
import {
  CampaignFulfillmentConfig,
  CampaignTypes,
  CampaignsApiModel,
  CreateIncentiveCampaignApiModel,
  CustomerActivitiesType,
  InvitationSMSConfig,
  PatchIncentiveCampaignApiModel,
} from '../../../api/models/campaigns';
import { postTransactionBatch } from '../../../store/slices/dataSyncSlice';
import { TransactionRecord } from '../../../api/models/transaction';
import { InitialState, resetNewCampaign } from '../../../store/slices/createCampaignSlice';
import { reset as resetUpload } from '../../../store/slices/uploadVideoSlice';
import { UserApiModel } from '../../../api/models/users';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { NewCampaignEvents } from '../../constants/events/newCampaignEvents';
import { UnsavedChangesModal } from '../UnsavedChangesModal/UnsavedChangesModal';

import _isEqual from 'lodash/isEqual';
import { colorPalette } from '../../../pages/NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import { replaceMessageBodyWithApiVariables } from '../SMS/SMS.helper';
import { useSetDefaultSMSTemplates } from '../../../pages/NewCampaign/CustomizeSMS/DefaultSMSTemplates/useSetDefaultSMSTemplates';
import { WelcomeVideoStylesType } from '../../../pages/NewCampaign/WelcomeVideoStyles/WelcomeVideoStyles';
import { MessageFrequency } from '../../../api/models/rewardFulfillment';
import { getIsNullReward } from '../../../services/utilities';

interface Props {
  btnSaveDisabled?: boolean;
  handleTrackEvent: IEventTracker;
  isEditMode?: boolean;
  editCampaignId?: string;
}

export const NewCampaignTopBar: FC<Props> = ({
  handleTrackEvent,
  btnSaveDisabled,
  isEditMode,
  editCampaignId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const [isCampaignCreating, setIsCampaignCreating] = useState(false);

  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const defaultCardColor =
    colorPalette.flat().find((color) => color === venueBrandColor) || '#D1D1D1';

  const createCampaignState = useTypedSelector((state) => state.createCampaign);

  const [initialState, setInitialState] = useState<InitialState>();
  useEffect(() => {
    setInitialState(createCampaignState);
    // should call only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUnsavedChangesOnPage = useMemo(() => {
    return !_isEqual(initialState, createCampaignState);
  }, [createCampaignState, initialState]);

  const {
    defaultInviteMessage,
    defaultRewardMessage,
    defaultFollowupMessages,
  } = useSetDefaultSMSTemplates();

  const {
    campaignName,
    campaignType,
    howToRewardCreators: {
      // discountType,
      cardColor,
      contact,
      rewardHeader,
      rewardName,
      contactMethod,
    },
    customerActivityType,
    isPreferred,
    emailRequired,
    isLocked,
    SMSMethod,
    campaignUsers,
    smsInviteMessage,
    inviteMediaUrl,
    smsRewardMessage,
    recommendedVideoStyle,
    showWelcomeVideo,
    welcomeVideoUrl,
    ctaButtonText,
    ctaButtonLinkUrl,
    messageFrequency,
    value,
  } = createCampaignState;

  const [showUnsavedModal, setShowUnsavedModal] = useState(true);
  const isNullReward = getIsNullReward(campaignType);

  const handleBack = () => {
    // sync state to prevent "Unsaved Changes" modal show
    flushSync(() => {
      setShowUnsavedModal(false);
    });
    history.push(`${WebsiteRoutes.RewardCampaigns}${isEditMode ? `/${editCampaignId}` : ''}`);
    handleTrackEvent(NewCampaignEvents.NewRewardCampaignBackClick);
    setShowUnsavedModal(true);
  };

  const isManuallyAddedRecord = (user: UserApiModel | TransactionRecord) =>
    'isManuallyAdded' in user && user?.isManuallyAdded;

  const handleSave = async () => {
    const rewardDescription = isNullReward
      ? RewardStrings.IsNullRewardDefaultDescription
      : RewardStrings.DefaultRewardSubtitle;

    const invitationMsgConfig: InvitationSMSConfig = {
      mediaUrl: inviteMediaUrl,
      body: replaceMessageBodyWithApiVariables(smsInviteMessage || defaultInviteMessage, true),
    };

    const fulfillmentConfig: CampaignFulfillmentConfig = {
      method: SMSMethod,
      sms: {
        mediaUrl: '',
        body: replaceMessageBodyWithApiVariables(smsRewardMessage || defaultRewardMessage, true),
      },
    };

    const rewardTitles: Record<CampaignTypes, string> = {
      [CampaignTypes.CUSTOM_REWARD]: rewardName,
      [CampaignTypes.MONTHLY_GIVEAWAY]: '$1k Monthly Giveaway',
      [CampaignTypes.NO_REWARD]: '',
    };

    const isCustomWelcomeVideoUrl =
      recommendedVideoStyle === WelcomeVideoStylesType.custom &&
      createCampaignState.uploadedWelcomeVideoUrl;
    const campaignData: CreateIncentiveCampaignApiModel = {
      accountId: venueId,
      name: campaignName.trim(),
      imageUrl: '',
      points: DEFAULT_POINTS_GOAL,
      isLocked,
      isPreferred,
      emailRequired,
      rewardTitle: rewardTitles[campaignType],
      rewardDescription: isNullReward ? '' : rewardDescription,
      campaignObjective: isNullReward ? CustomerActivitiesType.VideoUpload : customerActivityType,
      rewardType: value,
      campaignType,
      fulfillmentConfig,
      invitationMsgConfig,
      recommendedVideoStyle,
      rewardHeader: rewardHeader,
      cardStyle: cardColor || defaultCardColor,
      cardEnabled: !isNullReward,
      contact: contact,
      contactMethod: contactMethod,
      welcomeVideoUrl: isCustomWelcomeVideoUrl
        ? createCampaignState.uploadedWelcomeVideoUrl
        : welcomeVideoUrl,
      showWelcomeVideo,
      ctaButtonText,
      ctaButtonLinkUrl,
      isMultiFulfillment: messageFrequency === MessageFrequency.EVERY,
      value,
      followUpMessages: defaultFollowupMessages.map((message) => ({
        ...message,
        body: replaceMessageBodyWithApiVariables(message.body, true),
      })),
    };

    setIsCampaignCreating(true);
    const { id } = (await dispatch(postIncentiveCampaign(campaignData)))
      .payload as CampaignsApiModel;

    const existingUsers = campaignUsers.items
      .filter((record) => !isManuallyAddedRecord(record))
      .map((record) => ({
        firstName: record?.name || '',
        lastName: '',
        phoneNumber: record.phoneNumber,
        optIn: '',
        attributes: {
          additionalProp1: '',
          additionalProp2: '',
          additionalProp3: '',
        },
        refId: '',
        email: record.email,
        transactionDate: '',
        derivedTransactionDate: '',
      }));

    // await dispatch(
    //   addUsersToCampaign({
    //     campaignId: id,
    //     payload: {
    //       userIds,
    //     },
    //   }),
    // );

    const manuallyAddedReccords = campaignUsers.items.filter((record) =>
      isManuallyAddedRecord(record),
    );

    const records = [...manuallyAddedReccords, ...existingUsers].map((record) => {
      //@ts-ignore
      const { isManuallyAdded, optedIn, ...rest } = record;

      return {
        ...rest,
      };
    });

    if (records.length > 0) {
      await dispatch(
        //@ts-ignore
        postTransactionBatch({ venueId, campaignId: id, name: campaignName, records }),
      );
    }
    dispatch(resetNewCampaign());
    dispatch(resetUpload());
    dispatch(getPrimaryCampaign({ venueId }));
    setIsCampaignCreating(false);
    handleTrackEvent(NewCampaignEvents.NewRewardCampaignSaveAndCreateClick);

    // sync state to prevent "Unsaved Changes" modal show
    flushSync(() => {
      setShowUnsavedModal(false);
    });
    history.push(`${WebsiteRoutes.RewardCampaigns}/${id}`);
  };

  const handleUpdate = async () => {
    setIsCampaignCreating(true);

    const invitationMsgConfig: InvitationSMSConfig = {
      mediaUrl: inviteMediaUrl,
      body: replaceMessageBodyWithApiVariables(smsInviteMessage, true),
    };

    const fulfillmentConfig: CampaignFulfillmentConfig = {
      method: SMSMethod,
      sms: {
        mediaUrl: '',
        body: replaceMessageBodyWithApiVariables(smsRewardMessage, true),
      },
    };

    const rewardData = isNullReward
      ? {}
      : {
          rewardName,
          rewardHeader,
          cardStyle: cardColor,
          contact: contact,
          contactMethod: contactMethod,
        };

    const patchData: PatchIncentiveCampaignApiModel = {
      invitationMsgConfig,
      emailRequired,
      fulfillmentConfig,
      name: campaignName.trim(),
      ...rewardData,
      isMultiFulfillment: messageFrequency === MessageFrequency.EVERY,
    };
    if (editCampaignId) {
      await dispatch(patchIncentiveCampaign({ campaignId: editCampaignId, values: patchData }));
    }

    dispatch(resetNewCampaign());
    flushSync(() => {
      setShowUnsavedModal(false);
    });
    history.push(`${WebsiteRoutes.RewardCampaigns}/${editCampaignId}`);
  };

  return (
    <Box className={classes.container}>
      <UnsavedChangesModal
        isUnsavedChangesOnPage={showUnsavedModal && isUnsavedChangesOnPage}
        ignoreSubroutes={[WebsiteRoutes.NewCampaign, WebsiteRoutes.EditCampaign]}
      />
      <RoundedButton
        title={NewCampaignStrings.BtnBack}
        outlined={true}
        className={classes.btnBack}
        onClick={handleBack}
        id="go-back-button"
      />
      <Typography className={classes.title}>
        {isEditMode ? NewCampaignStrings.EditCampaignTopBarTitle : NewCampaignStrings.TopBarTitle}
      </Typography>
      <RoundedButton
        title={isEditMode ? NewCampaignStrings.BtnUpdate : NewCampaignStrings.BtnSave}
        className={classes.btnSave}
        onClick={isEditMode ? handleUpdate : handleSave}
        endIcon={<NextIcon />}
        loading={isCampaignCreating}
        disabled={btnSaveDisabled}
        id="save-and-create-button"
      />
    </Box>
  );
};
