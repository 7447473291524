import { httpClient } from '../services/httpClient/httpClient';
import { GetDataById } from './models/common';
import { UserMetricsAPIModel } from './models/users';
import {
  AccountCreationTokenEndpoints,
  getApiUrlForId,
  ServiceAgreementEndpoints,
  VideosEndpoints,
} from './endpoints';
import { VideosMetricsApiModel } from './models/videos';

export const getVideoMetrics = async (options: GetDataById) =>
  await httpClient.get<GetDataById, UserMetricsAPIModel>({
    url: getApiUrlForId(VideosEndpoints.GetVideoMetrics, options.id),
    requiresToken: true,
  });
export const getSVmetrics = async (options: { id: string }) =>
  await httpClient.get<{ id: string }, VideosMetricsApiModel>({
    url: getApiUrlForId(VideosEndpoints.GetSvMetrics, options.id),
    requiresToken: true,
  });

interface generateServiceAgreementPayload {
  paramOverrides: {
    COMPANY_NAME?: string;
    CUSTOMER_NAME?: string;
  };
  pricingPlanIdOrCode: string;
}

interface generateServiceAgreementPorps {
  payload: generateServiceAgreementPayload;
  captchaResponse: string;
  isEnterprise: boolean;
}

export const generateServiceAgreement = async (options: generateServiceAgreementPorps) => {
  return httpClient.post<generateServiceAgreementPayload, Blob>({
    url: options.captchaResponse
      ? `${ServiceAgreementEndpoints.generateServiceAgreement}?${
          options.isEnterprise ? 'eCaptchaResponse' : 'CaptchaResponse'
        }=${options.captchaResponse}`
      : `${ServiceAgreementEndpoints.generateServiceAgreement}`,
    requiresToken: localStorage.getItem('authToken') ? true : false,
    isBlob: true,
    payload: options.payload,
  });
};

export interface AccountCreationTokenAPIModel {
  id: string;
  payload: string | null;
  createdAccountId: string | null;
}

export const postAccountCreationToken = async (options?: { payload?: string }) => {
  return await httpClient.post<{ payload?: string }, AccountCreationTokenAPIModel>({
    url: `${AccountCreationTokenEndpoints.createAccountCreationToken}`,
    requiresToken: true,
    payload: options,
    contentType: 'application/json',
  });
};

export const getAccountCreationTokenRecord = async (token: string) => {
  return await httpClient.get<string, AccountCreationTokenAPIModel>({
    url: getApiUrlForId(AccountCreationTokenEndpoints.getAccountCreationToken, token),
    requiresToken: false,
  });
};
