import { Box, Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import {
  HelpersStrings,
  HeroesPageStrings,
  SalesHeroInfoRewardsStrings,
} from '../../../../localization/en';
import { makeStyles } from '@mui/styles';
import { getLocalDateString } from '../../../../../services/utilities';
import { useTypedSelector } from '../../../../../store';
// import { AchievementIcon } from '../../../../assets/AchievementIcon';
import { PointsStarIcon } from '../../../../assets/PointsStarIcon';

import { ClicksIconMedium } from '../../../../assets/newDesign/ClicksIconMedium';
import { ConversionsIcon } from '../../../../assets/newDesign/UserDetails/index';
import { SharesIconNew } from '../../../../assets/searchFilterIcons/SharesIconNew';
import { EyeIcon } from '../../../../assets/EyeIcon';
import { UserInvitedCampaignsApiModel } from '../../../../../api/models/campaigns';
import { PlayFillIcon } from '../../../../assets/PlayFillIcon';
import { PlayIcon } from '../../../../assets/newDesign/PlayIcon';
import { CreatorIcon } from '../../../../assets/newDesign/CreatorsIcon';
import { RewardIcon } from '../../../../assets/newDesign/RewardIcon';

interface SalesHeroInfoRewardsProps {
  isCampaignUser?: boolean;
  userInvitedCampaigns?: UserInvitedCampaignsApiModel[];
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    '&:before': {
      content: '',
    },
  },
  avatar: {
    display: 'flex',
    height: '70px',
    width: '70px',
    borderRadius: '100%',
    marginRight: '20px',
  },
  infoBlockRow: {
    display: 'flex',
    gridTemplateColumns: 'repeat(5,1fr)',
    gap: '30px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    borderRadius: '10px',
    padding: '30px 30px 15px',
  },
  infoBlockContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    padding: '0 10px',
  },
  iconContainer: {
    minWidth: '36px',
    width: '36px',
    padding: '8px',
    borderRadius: '30px',
    margin: 'auto 7px auto 0',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems:'center',
  },
  iconSvg: {
    display: 'block',
    width: '20px',
    height: '20px',
    fill: '#8B89A0',
  },
  arrowOutlined: {
    border: '1px solid #8B89A0',
    borderRadius: '50%',
  },
  infoBlockContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoBlockName: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: '6px',
  },
  infoBlockValue: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '32px',
    color: '#1A1538',
    marginBottom: '6px',
    letterSpacing: '0.02em',
  },
  table: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    width: '100%',
  },
  row: {
    padding: '26px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #EAECF0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  rowName: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#667085',
  },
  rowValue: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#475467',
  },
  divider: {
    marginBottom: '22px',
  },
}));

const ICON_SIZE = 30;

export const SalesHeroInfoRewards: FC<SalesHeroInfoRewardsProps> = ({
  isCampaignUser,
  userInvitedCampaigns,
}) => {
  const styles = useStyles();
  const { campaignUser, userDetails } = useTypedSelector((state) => state.userDetails);

  const renderInfoBlock = (block: string, value: number | string) => {
    let icon;
    const date = typeof value === 'string' && new Date(value).toLocaleDateString('en-US');
    const dateValue = date === 'Invalid Date' ? '---' : date;

    switch (block) {
      case HeroesPageStrings.NumberOfStories: {
        icon = <PlayFillIcon color="#8B89A0" width={ICON_SIZE} height={ICON_SIZE} />;
        break;
      }
      case HeroesPageStrings.Plays: {
        icon = <PlayIcon width={ICON_SIZE} height={ICON_SIZE} />;
        break;
      }
      case HeroesPageStrings.Viewers: {
        icon = <CreatorIcon width={ICON_SIZE} height={ICON_SIZE} />;
        break;
      }
      case HeroesPageStrings.Views: {
        icon = (
          <div style={{ margin: '0 -2px' }}>
            <EyeIcon color="#8B89A0" width="27px" height="28px" />
          </div>
        );
        break;
      }
      case HeroesPageStrings.CTA: {
        icon = (
          <div style={{ margin: '-2px' }}>
            <ClicksIconMedium color="#8B89A0" />
          </div>
        );
        break;
      }
      case HeroesPageStrings.Shares: {
        icon = <SharesIconNew />;
        break;
      }
      case HeroesPageStrings.TotalPoints: {
        icon = <PointsStarIcon color="#8B89A0" />;
        break;
      }
      case HeroesPageStrings.RewardsNumber: {
        icon = <RewardIcon width={ICON_SIZE} height={ICON_SIZE} />;
        break;
      }
      case HeroesPageStrings.Conversions: {
        icon = <ConversionsIcon />;
        break;
      }
    }
    return (
      <Box
        className={styles.infoBlockContainer}
        marginLeft={block === HeroesPageStrings.NumberOfStories ? '-30px' : 0}
      >
        <Box className={styles.iconContainer}>{icon}</Box>
        <Box className={styles.infoBlockContent}>
          <Typography className={styles.infoBlockValue}>
            {typeof value !== 'string' ? (value ? value : HelpersStrings.NotAvailable) : dateValue}
          </Typography>
        </Box>
      </Box>
    );
  };

  const showCampaignUser = isCampaignUser && campaignUser != null;

  const renderRowBlock = (name: string, value: string) => {
    return (
      <Box className={styles.row}>
        <Typography className={styles.rowName}>{name}</Typography>
        <Typography className={styles.rowValue}>{value}</Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid container direction="column" className={styles.root}>
        <Box className={styles.infoBlockRow}>
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Videos,
            showCampaignUser ? campaignUser?.videos! : userDetails ? userDetails.videos : 0,
          )}
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Plays,
            showCampaignUser
              ? campaignUser?.svVideoPlays || 0
              : userDetails
              ? userDetails.svVideoPlays || 0
              : 0,
          )}
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Viewers,
            showCampaignUser
              ? campaignUser?.svUniqueVisitors || 0
              : userDetails
              ? userDetails.svUniqueVisitors || 0
              : 0,
          )}
          {/* {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Views,
            showCampaignUser ? campaignUser?.videoViews! : userDetails?.videoViews || '',
          )} */}
          {/* {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Shares,
            showCampaignUser ? campaignUser?.shares! : userDetails?.shares || '',
          )}
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.CTA,
            showCampaignUser ? campaignUser?.videoCtaClicks! : userDetails?.videoCtaClicks || '',
          )} */}
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Rewards,
            showCampaignUser
              ? campaignUser?.fulfilled!
                ? 1
                : 0
              : userDetails?.rewardsFulfilled || '',
          )}
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.table}>
          {renderRowBlock(
            SalesHeroInfoRewardsStrings.DateJoined,
            getLocalDateString(userDetails?.createdAt || ''),
          )}
          {renderRowBlock(
            SalesHeroInfoRewardsStrings.LastClip,
            getLocalDateString(userDetails?.lastVideoCreatedAt || '') || '',
          )}
          {renderRowBlock(
            SalesHeroInfoRewardsStrings.LastInviteSent,
            getLocalDateString(
              userInvitedCampaigns ? userInvitedCampaigns[0]?.lastAttemptAt : '',
            ) || '',
          )}
          {renderRowBlock(
            SalesHeroInfoRewardsStrings.OptedOut,
            userDetails?.smsOptedIn ? '---' : 'Yes',
          )}
        </Box>
      </Grid>
    </>
  );
};
