import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { ITab } from '../../Carousel/Carousel.helper';
import { useClasses } from './Tabs.helper';

interface TabProps {
  tab: ITab;
  onClick: () => void;
  isActive: boolean;
  tabWidth?: string;
}

export const Tab: React.FC<TabProps> = ({ tab, onClick, isActive, tabWidth = '100%' }) => {
  const classes = useClasses();
  return (
    <Box
      onClick={onClick}
      className={clsx(classes.root, { [classes.active]: isActive })}
      style={{ width: tabWidth }}
    >
      <Box className={classes.tabIcon}>{tab.icon}</Box>
      <Typography className={classes.tabText}>{tab.text}</Typography>
    </Box>
  );
};
