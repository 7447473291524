import { Box, Typography } from '@mui/material';

import { useStyles } from './CustomizationSection.styles';
import { PersonalizationStrings, WebAppPageStrings } from '../../../../common/localization/en';
import {
  AccordionItem,
  QuickStartAccordion,
} from '../../../../common/components/QuickStartAccordion/QuickStartAccordion';
import { PersonalizationSubroutes, WebsiteRoutes } from '../../../../common/constants/routes';
import { BrandColorSection } from './sections/BrandColorSection';
import { LogoUploader } from '../../../../common/components/ImageUploader/uploaders/LogoUploader';
// import { BackgroundImageUploader } from '../../../../common/components/ImageUploader/uploaders/BackgroundImageUploader';
import { useEffect } from 'react';
import { QuickStartEventNames } from '../../../../common/constants/events/quickstartEvents';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import AnimationStyleSelect, {
  AnimationStyleOptions,
} from '../../../../common/components/AnimationStyleSelect/AnimationStyleSelect';
import { Tooltip } from '../../../../common/components/Tooltip/Tooltip';

interface CustomizationSectionProps {
  logo: string | undefined;
  onLogoChange?: (src: string | undefined) => void;

  background: string | undefined;
  onBackgroundChange?: (src: string | undefined) => void;
  onBackgroundPreviewChange?: (src: string | undefined) => void;
  onBackgroundNextClick?: () => void;

  color: string;
  onColorChange?: (color: string) => void;
  setColorError: (value: boolean) => void;
  colorError: boolean;
  onColorNextClick?: () => void;
  onSetAccordionSubroutes: (accordionSubroutes: string[]) => void;

  animationsStyle: AnimationStyleOptions;
  onAnimationStyleChange: (value: AnimationStyleOptions) => void;
}

export const CustomizationSection: React.FC<CustomizationSectionProps> = ({
  logo,
  onLogoChange,
  background,
  onBackgroundChange,
  onBackgroundPreviewChange,
  onBackgroundNextClick,
  color,
  onColorChange,
  onColorNextClick,
  onSetAccordionSubroutes,
  setColorError,
  colorError,
  animationsStyle,
  onAnimationStyleChange,
}) => {
  const classes = useStyles();
  const { trackEvent } = useTrackEvent();
  const accordionItems: AccordionItem[] = [
    {
      sectionContent: (
        <BrandColorSection
          color={color}
          onColorChange={onColorChange}
          setColorError={setColorError}
        />
      ),
      subroute: PersonalizationSubroutes.SelectBrandColor,
      title: PersonalizationStrings.BrandColorTitle,
      tooltipText: 'Brand color tooltip',
      onNextClick: onColorNextClick,
    },
    {
      sectionContent: (
        <LogoUploader
          logo={logo}
          onLogoChange={(newLogo) => {
            if (onLogoChange) onLogoChange(newLogo);
            trackEvent(QuickStartEventNames.company_logo_upload, { value: newLogo });
          }}
          title={PersonalizationStrings.UploadImage}
          subtitle={[
            PersonalizationStrings.UploadImageSubtitle1,
            PersonalizationStrings.UploadImageSubtitle2,
            PersonalizationStrings.UploadImageSubtitle3,
          ]}
          isQuickstart
        />
      ),
      subroute: PersonalizationSubroutes.UploadLogo,
      title: PersonalizationStrings.LogoTitle,
      tooltipText: 'Upload logo tooltip',
    },
    {
      sectionContent: (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Box className={classes.infoWrapper}>
            <Typography className={classes.infoText}>{WebAppPageStrings.InfoText}</Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                width: '100%',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {onAnimationStyleChange && (
                  <AnimationStyleSelect
                    splashImage={''}
                    logo={logo || ''}
                    value={animationsStyle as AnimationStyleOptions}
                    onChange={(value) => {
                      onAnimationStyleChange(value);
                    }}
                  />
                )}
                <Tooltip
                  content={<Box>{WebAppPageStrings.AnimationSelectTooltip}</Box>}
                  iconClassName={classes.tooltipIcon}
                />
              </Box>
              <p style={{ color: '#475467', marginLeft: '16px' }}>
                {'Animation beginning > Animation Ending'}
              </p>
            </Box>
          </Box>

          {/* <BackgroundImageUploader
            backgroundImage={background}
            onImageChange={(newBackground) => {
              if (onBackgroundChange) onBackgroundChange(newBackground);
              trackEvent(QuickStartEventNames.video_app_background_image_upload, {
                value: newBackground,
              });
            }}
            onPreviewChange={onBackgroundPreviewChange}
            title={PersonalizationStrings.BackgroundTitle}
            subtitle={[
              PersonalizationStrings.BackgroundSubtitle1,
              PersonalizationStrings.BackgroundSubtitle2,
              PersonalizationStrings.BackgroundSubtitle3,
            ]}
          /> */}
        </Box>
      ),
      subroute: PersonalizationSubroutes.UploadBackgroundImage,
      title: PersonalizationStrings.BackgroundImageTitle,
      tooltipText: WebAppPageStrings.FirstStageTooltip,
      onNextClick: () => {
        onBackgroundPreviewChange?.(undefined);
        onBackgroundNextClick?.();
      },
    },
  ];

  useEffect(() => {
    onSetAccordionSubroutes(accordionItems.map((item) => item.subroute));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{PersonalizationStrings.Title}</Typography>
      <Typography className={classes.description}>
        {PersonalizationStrings.CustomizeWebApp}
      </Typography>
      <Box className={classes.divider} />
      <Box className={classes.accordionWrapper}>
        <QuickStartAccordion
          accordionItems={accordionItems}
          baseRoute={WebsiteRoutes.QuickStartPersonalize}
          colorError={colorError}
        />
      </Box>
    </Box>
  );
};
