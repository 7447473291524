import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './InvitationMessageTab.styles';
import {
  CustomizeInvitationsModalStrings,
  ElementsStrings,
} from '../../../../../../common/localization/en';
import { useTypedSelector } from '../../../../../../store';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
  replaceMessageBodyWithLocalVariables,
} from '../../../../../../common/components/SMS/SMS.helper';
import { useClientType } from '../../../../../../services/hooks/useClientType';
import { RoundedButton } from '../../../../../../common/components/Button/RoundedButton';
import { SendTestMessageModal } from '../../../../../../common/components/SMS/SendTestMessageModal';
import { useModal } from '../../../../../../hooks/useModal';
import { useSmsTemplateVariables } from '../../../../../../services/hooks/useSmsTemplateVariables';
import { MessageBox } from '../../../../../../common/components/IPhonePreview/IPhoneSMSPreview';
import { CustomizeInvitationsModal } from '../../../../../../common/components/CusomizeInvitationsModal/CustomizeInvitationsModal';
import { Tooltip } from '../../../../../../common/components/Tooltip/Tooltip';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { useParams } from 'react-router-dom';
import { RewardCampaignsEvents } from '../../../../../../common/constants/events/rewardCampaignsEvents';
import { postInvitationSelfInviteAPICall } from '../../../../../../store/slices/campaignsSlice';
import { useToasts } from '../../../../../../services/hooks/useToast';
import { formatPhoneNumber, getIsNullReward } from '../../../../../../services/utilities';
import { DaysSelector } from './DaysSelector/DaysSelector';

export const InvitationMessageTab: FC = () => {
  const classes = useStyles();
  const { id: campaignId } = useParams<{ id: string }>();

  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();
  const { addToast } = useToasts();
  const [dayNumberSelected, setDayNumberSelected] = useState(1);

  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const { phoneNumber, name } = useTypedSelector((store) => store.me);

  const isDefaultMessage = dayNumberSelected === 1;

  const inviteImage = isDefaultMessage ? currentCampaign?.invitationMsgConfig?.mediaUrl : '';

  const inviteMessage = replaceMessageBodyWithLocalVariables(
    (isDefaultMessage
      ? currentCampaign?.invitationMsgConfig?.body
      : currentCampaign?.followUpMessages.find(
          (message) => message?.delayInDays === dayNumberSelected,
        )?.body) || '',
    isHealthCare,
    true,
  );

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: getIsNullReward(currentCampaign?.campaignType)
      ? [LocalMessageVariables.RewardName]
      : [],
  });

  const constructedTestPostMessage = constructMessageFromTemplate(
    inviteMessage,
    templateVariables,
    true,
  );

  const {
    isOpen: isTestMessageModalOpen,
    open: openTestMessageModal,
    close: closeTestMessageModal,
  } = useModal();

  const {
    isOpen: isCustomizeInviteModalOpen,
    open: openCustomizeInviteModal,
    close: closeCustomizeInviteModal,
  } = useModal(false);

  const formattedNumber = phoneNumber?.startsWith('+1')
    ? formatPhoneNumber(phoneNumber, { showPlusOne: false })
    : phoneNumber;

  return (
    <Box className={classes.container}>
      {isCustomizeInviteModalOpen && (
        <CustomizeInvitationsModal
          isOpenModal={isCustomizeInviteModalOpen}
          onClose={closeCustomizeInviteModal}
          dayNumberSelected={dayNumberSelected}
        />
      )}
      <SendTestMessageModal
        modalIsOpen={isTestMessageModalOpen}
        onClose={() => {
          closeTestMessageModal();
        }}
        testMessage={constructedTestPostMessage}
        onTestMessageSendClick={async () => {
          postInvitationSelfInviteAPICall({
            campaignId,
            followUpAfter: dayNumberSelected !== 1 ? dayNumberSelected : undefined,
          })
            .then(() => {
              addToast(`${ElementsStrings.InviteSent}${formattedNumber}`, {
                appearance: 'success',
              });
            })
            .catch(() => {
              addToast(ElementsStrings.InviteSent, {
                appearance: 'success',
              });
            });
        }}
        phoneNumber={phoneNumber}
        name={name}
      />
      <Box className={classes.leftSideWrapper}>
        <Typography className={classes.title}>{ElementsStrings.InvitationText}</Typography>

        <Box className={classes.contentWrapper}>
          <Typography className={classes.subtitle}>
            {isHealthCare ? ElementsStrings.InvitationSentTrublu : ElementsStrings.InvitationSent}
            <Tooltip
              content={<Box>{ElementsStrings.VerifiedLink}</Box>}
              icon={
                <Typography display="inline" className={classes.linkText}>
                  {ElementsStrings.PersonalizedLink}
                </Typography>
              }
            ></Tooltip>
            {isHealthCare ? ElementsStrings.InvitationSentEnd : ElementsStrings.InvitationSentEnd}
          </Typography>
          <Box className={classes.daySelectorWrapper}>
            <DaysSelector
              dayNumberSelected={dayNumberSelected}
              setDayNumberSelected={setDayNumberSelected}
            />
          </Box>
        </Box>

        {!currentCampaign?.endedAt && (
          <Box className={classes.buttonsWrapper}>
            <RoundedButton
              className={classes.customizeButton}
              title={ElementsStrings.Customize}
              onClick={() => {
                openCustomizeInviteModal();
                trackEvent(RewardCampaignsEvents.OpenCustomizeInviteModal, {
                  campaignId,
                  daySelected: dayNumberSelected,
                });
              }}
            />

            <RoundedButton
              className={classes.sendMessageButton}
              title={ElementsStrings.SendSampleMessage}
              outlined={true}
              onClick={() => {
                openTestMessageModal();
                trackEvent(RewardCampaignsEvents.OpenTestMessageModal, {
                  campaignId,
                });
              }}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.rightSideWrapper}>
        <Typography className={classes.howAppearText}>{ElementsStrings.WhatItLooksLike}</Typography>
        <Box className={classes.phonePreviewWrapper}>
          <Box className={classes.phoneContentWrapper}>
            {inviteImage && (
              <img className={classes.inviteImagePhone} src={inviteImage} alt="invite" />
            )}
            <Box className={classes.messageBoxWrapper}>
              <MessageBox
                width="258px"
                fontSize={15}
                message={inviteMessage}
                linkTitle={CustomizeInvitationsModalStrings.PersonalizeLink}
                boldSubstrings={Object.values(LocalMessageVariables)}
                showStartBlock={false}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
