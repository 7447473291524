import { Box } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { WelcomeVideoStylesType } from '../WelcomeVideoStyles';
import { useStyles } from './SelectWelcomeVideoType.styles';
import {
  setRecommendedVideoStyle,
  setWelcomeVideoToggle,
  setWelcomeVideoUrl,
} from '../../../../store/slices/createCampaignSlice';
import { reset } from '../../../../store/slices/uploadVideoSlice';
import { defaultVideos } from '../../WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';
import { useClientType } from '../../../../services/hooks/useClientType';
import { NewCampaignStrings } from '../../../../common/localization/en';

const SelectWelcomeVideoType = () => {
  const classes = useStyles();
  const { recommendedVideoStyle } = useTypedSelector((state) => state.createCampaign);
  const dispatch = useAppDispatch();
  const { isUwmClient } = useClientType();

  const changeType = (type: string) => {
    dispatch(setRecommendedVideoStyle(type));
    dispatch(setWelcomeVideoToggle(type === WelcomeVideoStylesType.none ? false : true));

    if (type === WelcomeVideoStylesType.inspirational) {
      dispatch(setWelcomeVideoUrl(isUwmClient ? defaultVideos.defaultUwm : defaultVideos.default));
    }
    dispatch(reset());
  };

  return (
    <Box className={classes.wrapper}>
      {Object.keys(WelcomeVideoStylesType)
        .filter((videoType) => videoType !== WelcomeVideoStylesType.none)
        .map((videoType) => (
          <Box
            onClick={() => changeType(videoType)}
            className={`${classes.item} ${
              videoType === recommendedVideoStyle ? classes.active : ''
            }`}
          >
            {videoType === WelcomeVideoStylesType.inspirational
              ? NewCampaignStrings.Default
              : videoType}
          </Box>
        ))}
    </Box>
  );
};

export default SelectWelcomeVideoType;
