import { FC } from 'react';

type PlayIconIconProps = {
  color?: string;
  width?: number;
  height?: number;
};
export const PlayIcon: FC<PlayIconIconProps> = ({ width = 30, height = 25 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 30 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.83402 20.1729H26.3328C28.3894 20.1729 29.4629 19.1332 29.4629 17.0427V4.00223C29.4629 1.91169 28.3894 0.87207 26.3328 0.87207H3.83402C1.77738 0.87207 0.703857 1.91169 0.703857 4.00223V17.0427C0.703857 19.1332 1.77738 20.1729 3.83402 20.1729ZM12.2414 15.8675C11.6538 15.8675 11.1792 15.4268 11.1792 14.6244V6.40918C11.1792 5.60686 11.6538 5.17746 12.2414 5.17746C12.5352 5.17746 12.7838 5.26786 13.0663 5.41476L19.8803 9.42634C20.3888 9.73145 20.6375 10.0705 20.6375 10.5112C20.6375 10.9632 20.3775 11.3135 19.8803 11.6073L13.0663 15.6189C12.7838 15.7771 12.5352 15.8675 12.2414 15.8675ZM9.30333 24.1279H20.8635C21.4285 24.1279 21.8918 23.6646 21.8918 23.0996C21.8918 22.5346 21.4285 22.06 20.8635 22.06H9.30333C8.73832 22.06 8.27501 22.5346 8.27501 23.0996C8.27501 23.6646 8.73832 24.1279 9.30333 24.1279Z"
          fill="#8B89A0"
        />
      </svg>
    </>
  );
};
