import { VideoDetails } from './videos';

interface InstagramProfileData {
  followers: number;
  following: number;
}

interface InstagramProfileValues {
  data: InstagramProfileData | null;
}

export enum UserStatuses {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  archived = 'ARCHIVED',
}

export interface UserApiModel {
  id: string;
  name: string | null;
  email: string | null;
  photoUrl: string | null;
  createdAt: string;
  lastVideoCreatedAt: string | null;
  phoneNumber: string | null;
  shares: number;
  points: number;
  currentCampaignIncentiveReceived: number;
  unsubscribed: boolean;
  accountId: string;
  returnUrl: string | null;
  videos: number;
  followers: number;
  redemptions: number;
  instagramHandle?: string;
  instagramProfile: InstagramProfileValues;
  totalPoints: number;
  rewardsFulfilled: number;
  campaignPoints: number;
  displayName: string | null;
  status: UserStatuses;
  convertedReferrals: number;
  videoCtaClicks: number;
  lastInvitationSentAt: Date | null;
  phoneNumberVerified?: boolean;
  defaultVideoDetails: VideoDetails | null;
  videoViews: number;
  smsOptedIn?: boolean;
  optedIn?: boolean;
  optOut?: boolean;
  firstName: string;
  lastName: string;
  lastStatusChangedAt: string | null;
  lastStatusChangedBy: string | null;
  lastStatusChangedByFirstName: string | null;
  lastStatusChangedByLastName: string | null;
  activeCampaigns: number;
  svVideoPlays?: number | null;
  svUniqueVisitors?: number | null;
}

export interface CampaignUserApiModel {
  userId: string;
  campaignId: string;
  points: number;
  active: boolean;
  fulfilled: boolean;
  userName: string;
  userStatus: UserStatuses;
  phoneNumber: string;
  email: string;
  photoUrl: string;
  videos: number;
  videoViews: number;
  videoCtaClicks: number;
  lastVideoCreatedAt: string | null;
  phoneNumberVerified: boolean;
  shares: number;
  convertedReferrals: number;
  svVideoPlays?: number | null;
  svUniqueVisitors?: number | null;
}

export interface UserMetricsAPIModel {
  videoViews: number;
  referrals: number;
  convertedReferrals: number; //Purchases or Donations
}

export interface VideoMetricsStoreModel extends UserMetricsAPIModel {
  conversionRate?: string | number;
}

export interface HeroApiModel {
  id: string;
  fullName: string;
  email: string;
  createdAt: string;
  photoUrl: string | null;
  instagramHandle: string | null;
  videosCount: number;
}

export interface AddBonusPointsApiModel {
  success: boolean;
}
