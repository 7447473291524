import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  MonthlyInfo: {
    fontSize: '14px',
    color: theme.palette.common.gray600,
    gridArea: 'info',
    marginTop: '-10px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.common.gray900,
  },
  closeButton: {
    color: '#667085',
  },
  sectionTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginTop: '20px',
  },
  rewardOptionsWrapper: {
    marginTop: '13px',
  },
  rewardOptionButton: {
    borderWidth: '2px',
    width: '169px',
    whiteSpace: 'nowrap',
    height: '35px',

    '&:hover': {
      borderWidth: '2px',
      color: theme.palette.primary.main,
    },
  },
  rewardNameWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '338px',
  },
  rewardNameLimit: {
    color: '#BCBCBC',
    fontSize: '14px',
    fontWegith: 500,
  },
  rewardInput: {
    width: '338px',
    marginTop: '6px',
  },
  selectWrapper: {
    margin: '6px 0',
  },
  info: {
    fontSize: '14px',
    color: theme.palette.common.gray600,
  },
  messageOptionsWrapper: {
    margin: '13px 0',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginTop: '32px',
  },
  button: {
    width: '243px',
    height: '44px',
  },
  cancelButton: {
    borderColor: '#D0D5DD',
    color: '#344054',
  },
}));
