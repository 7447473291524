import { Box, Grid } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CreatorDetailsSlideout } from '../../../../../common/components/CreatorDetailsSlideout/CreatorDetailsSlideout';

import { EmptyResultsPreview } from '../../../../../common/components/EmptyResultsPreview/EmptyResultsPreview';
import { SearchBar } from '../../../../../common/components/searchBar/SearchBar';
import { CommonTable } from '../../../../../common/components/table/CommonTable';
import { TabItem } from '../../../../../common/components/TableFilterSelect/TableFilterSelect';
import { defaultPagination } from '../../../../../common/constants/constants';
import { RewardCampaignsEvents } from '../../../../../common/constants/events/rewardCampaignsEvents';
import {
  CampaignInvitesStrings,
  CommonStrings,
  NoFilterResultsStrings,
  RewardsPageStrings,
} from '../../../../../common/localization/en';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { campaignCreatorsTableContent } from '../../../../../services/helpers/tableMappers';

import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
  getIsNullReward,
} from '../../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import {
  closeCampaignSummaryUserDetails,
  getCampaignUsers,
  getCampaignUsersAPICall,
  getCampaignVideos,
  goToSelectedPageExistingCampaignUsersTable,
  openCampaignSummaryUserDetails,
  setExistingCampaignUsersTableSorting,
  updateExistingCampaignUsersTableSearch,
  updateExistingCampaignUsersTableSize,
} from '../../../../../store/slices/campaignsSlice';
import { Stats } from '../../Stats/Stats';

import { useStyles } from './CreatorsTable.style';

interface Prop {}

export const CreatorsTable: FC<Prop> = () => {
  const styles = useStyles({});

  const dispatch = useAppDispatch();
  const { trackEvent: handleTrackEvent } = useTrackEvent();
  const { countData } = useTypedSelector((state) => state.campaigns);

  const { items, totalPages, totalItems, page, size, sort, search, isLoading } = useTypedSelector(
    (state) => state.campaigns.currentCampaignUsers,
  );
  const {
    currentCampaign,
    isCountDataLoading,
    campaignSummaryUserDetails,
    creatorsTableActiveTabIndex,
  } = useTypedSelector((state) => state.campaigns);

  const { id: campaignId } = useParams<{ id: string }>();

  const isIncentiveCampaign = !getIsNullReward(currentCampaign?.campaignType);

  type CreatorTabItem = TabItem & {
    fulfilled: boolean | null;
  };

  const tabsList: CreatorTabItem[] = useMemo(
    () => [
      {
        tabName: RewardsPageStrings.ViewAll,
        usersCount: (creatorsTableActiveTabIndex === 0 && isCountDataLoading
          ? 0
          : countData.activeUsers
        ).toString(),
        fulfilled: null,
      },
      {
        tabName: isIncentiveCampaign
          ? RewardsPageStrings.Rewarded
          : RewardsPageStrings.Acknowledged,
        usersCount: (creatorsTableActiveTabIndex === 1 && isCountDataLoading
          ? 0
          : countData.fulfilledUsers
        ).toString(),
        fulfilled: true,
      },
      {
        tabName: isIncentiveCampaign
          ? RewardsPageStrings.NotRewarded
          : RewardsPageStrings.NotAcknowledged,
        usersCount: (creatorsTableActiveTabIndex === 2 && isCountDataLoading
          ? 0
          : countData.activeUsers - countData.fulfilledUsers
        ).toString(),
        fulfilled: false,
      },
    ],
    [creatorsTableActiveTabIndex, countData, isCountDataLoading, isIncentiveCampaign],
  );

  const fetchItems = useCallback(() => {
    if (!campaignId) return;
    dispatch(
      getCampaignUsers({
        search,
        id: campaignId,
        pageable: { page, size, sort },
        hasVideos: true,
      }),
    );
  }, [campaignId, dispatch, page, size, sort, search]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const userDataTableContents = campaignCreatorsTableContent({
    items,
  });

  const getPredictiveSearchItems = async (search: string) => {
    const result = await getCampaignUsersAPICall({
      search,
      id: campaignId,
      pageable: { page: 0, size: 100, sort: defaultPagination.sortByLastCreated },
      filter: {
        fulfilled: tabsList[creatorsTableActiveTabIndex].fulfilled,
      },
      hasVideos: true,
    });

    const predictiveSearchItems = result.items.map((item) => item.userName || '').filter(Boolean);
    return [...new Set(predictiveSearchItems)];
  };

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }
    dispatch(goToSelectedPageExistingCampaignUsersTable(convertedTargetPage));
  };
  const onSort = (name: string) => {
    dispatch(setExistingCampaignUsersTableSorting(name));
    handleTrackEvent(
      RewardCampaignsEvents.RewardCampaignsActiveCustomersTableSortClick.replace(
        'sort_by_column',
        `${tabsList[creatorsTableActiveTabIndex].tabName}_tab_sort_by_${name}`,
      ).toLowerCase(),
    );
  };

  const onClickRow = (id: string) => {
    dispatch(openCampaignSummaryUserDetails(id));
    handleTrackEvent(RewardCampaignsEvents.RewardCampaginTableRowClick, { selectedUserId: id });
  };
  const changeSize = (size: number) => {
    dispatch(updateExistingCampaignUsersTableSize(size));
  };

  const closeUserModal = () => {
    dispatch(closeCampaignSummaryUserDetails());
  };

  const handleCampaignClipUpload = () => {
    if (!currentCampaign) return;

    dispatch(
      getCampaignUsers({
        search,
        id: currentCampaign.id,
        pageable: { page, size, sort },
        filter: {
          fulfilled:
            creatorsTableActiveTabIndex === 1
              ? true
              : creatorsTableActiveTabIndex === 2
              ? false
              : null,
        },
        hasVideos: true,
      }),
    );

    dispatch(
      getCampaignVideos({
        id: currentCampaign.id,
        pageable: {
          page: 0,
          size: 100000,
          sort: ['asc'],
        },
      }),
    );
  };

  return (
    <Box className={styles.container}>
      <CreatorDetailsSlideout
        userId={campaignSummaryUserDetails.userId}
        isOpen={campaignSummaryUserDetails.isOpen}
        handleClose={closeUserModal}
        campaignId={currentCampaign?.id}
        onUpload={handleCampaignClipUpload}
      />
      <Stats />
      <Box style={{ marginTop: '20px', marginRight: '20px', width: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="end">
          <SearchBar
            entity={'campaigns'}
            updateSearch={updateExistingCampaignUsersTableSearch}
            adaptiveWidth={true}
            disabled={isLoading}
            placeholderText={CommonStrings.SearchByName}
            borderColor={'#F2F4F7'}
            clearSearch={creatorsTableActiveTabIndex}
            getPredictiveSearchItems={getPredictiveSearchItems}
          />
        </Box>
        <Grid className={styles.table}>
          <CommonTable
            content={userDataTableContents}
            selectedRowId={campaignSummaryUserDetails.userId}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={false}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            withSearchBar={true}
            labelRowsPerPage={CampaignInvitesStrings.View}
            roundedNavButtons={false}
            disableTopNav={true}
            emptyTableCustomPreview={
              <EmptyResultsPreview
                title={NoFilterResultsStrings.NoFilterResultsCreatorsTitle}
                subtitle={NoFilterResultsStrings.NoFilterResultsSubTitle}
              />
            }
            showEmptyTableCustomPreview={(search || '').length > 0 && items.length === 0}
          />
        </Grid>
      </Box>
    </Box>
  );
};
