import { Box, LinearProgress, Typography } from '@mui/material';
import { FC } from 'react';
import { useStyles } from './GoalBlock.styles';
import { HeroesPageStrings, RewardsPageStrings } from '../../../../localization/en';
import { CampaignUserApiModel } from '../../../../../api/models/users';
import { CampaignsApiModel, CustomerActivitiesType } from '../../../../../api/models/campaigns';
import { RoundedButton } from '../../../Button/RoundedButton';
import { IconSuccessGreen } from '../../../../assets/IconSuccessGreen';
import { BonusPoints } from '../../../../assets/BonusPoints';
import { RewardCampaignsEvents } from '../../../../constants/events/rewardCampaignsEvents';
import {
  addBonusPoints,
  fetchUserDetails,
  getCampaignUser,
} from '../../../../../store/slices/userDetailsSlice';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { useAppDispatch } from '../../../../../store';
import { DEFAULT_POINTS_GOAL } from '../../../../constants/constants';
import { useHistory } from 'react-router-dom';
import { getIsNullReward } from '../../../../../services/utilities';

const getUserProgress = (
  campaignObjective: CustomerActivitiesType | undefined,
  user: CampaignUserApiModel,
) => {
  if (!campaignObjective) return 0;

  let progress = 0;
  switch (campaignObjective) {
    case CustomerActivitiesType.VideoUpload:
      if (user.videos) progress = 100;
      break;
    case CustomerActivitiesType.VideoViews:
      if (user.videos > 0) progress += 50;
      if (user.videoViews > 0) progress += 50;
      break;
    case CustomerActivitiesType.VideoClicks:
      if (user.videos > 0) progress += 33;
      if (user.videoViews > 0) progress += 33;
      if (user.videoCtaClicks > 0) progress += 34;
      break;
  }

  return progress;
};

interface Props {
  campaignUser: CampaignUserApiModel;
  campaign: CampaignsApiModel;
}

export const GoalBlock: FC<Props> = ({ campaignUser, campaign }) => {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isOnCreatorsPage = history.location.pathname.includes('/creators');

  const classes = useStyles();
  const { campaignType, campaignObjective } = campaign;
  const isNullReward=getIsNullReward(campaignType)
  const progress = getUserProgress(campaignObjective, campaignUser);
  const isPointsGoalAchieved = progress >= 100;

  const handleEnableAward = async () => {
    trackEvent(RewardCampaignsEvents.RewardEnableClick, {
      selectedUserId: campaignUser.userId,
      campaignId: campaign?.id || '',
    });
    await dispatch(
      addBonusPoints({
        id: campaignUser.userId,
        campaignId: campaign.id,
        points: DEFAULT_POINTS_GOAL,
      }),
    );
    await dispatch(getCampaignUser({ userId: campaignUser.userId, campaignId: campaign.id }));
    await dispatch(fetchUserDetails(campaignUser.userId));
  };

  return (
    <Box className={classes.contaner}>
      <Typography className={classes.goalText}>{HeroesPageStrings.GoalPercentage}</Typography>
      <Box className={classes.progressWrapper}>
        <LinearProgress value={progress} variant="determinate" className={classes.progressBar} />
        <Typography className={classes.percentsText}>{`${progress}%`}</Typography>
      </Box>
      {isPointsGoalAchieved ? (
        <Box className={classes.succesTextWrapper}>
          <IconSuccessGreen />
          <Typography className={classes.successText}>{HeroesPageStrings.GoalAchieved}</Typography>
        </Box>
      ) : (
        <Box className={classes.btnEnableWrapper}>
          <RoundedButton
            startIcon={<BonusPoints />}
            className={classes.btnEnableReward}
            disabled={!campaignUser.videos || isOnCreatorsPage}
            title={
              isNullReward ? RewardsPageStrings.EnableRecognition : RewardsPageStrings.EnableReward
            }
            onClick={handleEnableAward}
          />
        </Box>
      )}
    </Box>
  );
};
