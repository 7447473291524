import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { VenueAttributesDTO, VenueDTO, VenuesListDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { VenueEndpoints, getApiUrlForId } from '../../api/endpoints';
import {
  VenueAPIModel,
  PatchVenue,
  PatchVenueOptions,
  GetVenueProperties,
  VenueProperties,
  PatchVenueProperties,
  PatchVenuePropertiesOptions,
  GetServiceAgreementOptions,
  GetServiceAgreementResponse,
  PostServiceAgreementOptions,
  FinalizeAccountOptions,
} from '../../api/models/venue';
import { AdminVenueCreationOptions } from '../../api/models/venueCreation';
import { ClientTypes } from '../../api/models/common';
// import { createAlert } from './alertSlice';

interface VenueSlice {
  venueAttributes: VenueAttributesDTO;
  venue: VenueDTO;
  venuesList: VenuesListDTO[];
  selectedVenueId: string | null;
  isWebAppAvailable: boolean;
  shouldLogout: boolean;
  DMOList: {
    isLoading: boolean;
    items: { id: string; name: string }[];
  };
  serviceAgreement: {
    error: boolean;
    isLoading: boolean;
    value: string;
  };
}

const venueDataFromLocalStorage = () => {
  return localStorage.getItem('selectedVenue');
};

const initialState: VenueSlice = {
  venueAttributes: {
    properties: null,
    error: false,
    isLoading: false,
    lastUpdated: new Date().toISOString(),
  },
  venue: {
    organizationName: '',
    city: '',
    id: '',
    logo: '',
    orgId: '',
    name: '',
    subdomain: '',
    timeZone: '',
    refId: '',
    error: false,
    isLoading: false,
    lastUpdated: new Date().toISOString(),
    clientType: ClientTypes.FUNDRAISING,
    createdAt: '',
    websiteUrl: '',
    setupCompleted: false,
    tutorialCompleted: false,
    industry: null,
  },
  venuesList: [],
  selectedVenueId: venueDataFromLocalStorage(),
  isWebAppAvailable: false,
  shouldLogout: false,
  DMOList: {
    isLoading: false,
    items: [],
  },
  serviceAgreement: {
    error: false,
    isLoading: false,
    value: '',
  },
};

const logoutState = { ...initialState, selectedVenueId: null };

interface GetVenuOptions {
  id: string;
}

interface GetVenuesListOptions {
  managerId: string;
}

export const createVenue = createAsyncThunk(
  'venue/createVenue',
  async (options: AdminVenueCreationOptions, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<AdminVenueCreationOptions, VenueAPIModel>({
        url: VenueEndpoints.CreateVenue,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const finalizeAccount = createAsyncThunk(
  'venue/finalizeAccount',
  async (
    { id, properties, dmo_member, unverified_dmo_account, ...updates }: FinalizeAccountOptions,
    { rejectWithValue },
  ): Promise<any> => {
    try {
      return await httpClient.post<any, any>({
        url: getApiUrlForId(VenueEndpoints.FinalizeAccount, id),
        requiresToken: true,
        payload: {
          ...updates,
          properties: {
            dmo_member,
            unverified_dmo_account,
            ...properties,
          },
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getDMOList = createAsyncThunk(
  'venue/getDMOList',
  async (options: { isTest?: boolean }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.get<{}, string[]>({
        url: `${VenueEndpoints.GetDMOList}${options.isTest ? '?test=true' : ''}`,
        requiresToken: false,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getVenuesByAccountManagerId = createAsyncThunk(
  'venue/getVenuesList',
  async (options: GetVenuesListOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, VenuesListDTO[]>({
        url: getApiUrlForId(VenueEndpoints.GetVenuesByAccountManagerId, options.managerId),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAccountById = createAsyncThunk(
  'venue/getAccountById',
  async (options: GetVenuOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, VenueAPIModel>({
        url: getApiUrlForId(VenueEndpoints.GetAccountById, options.id),
        requiresToken: false,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getVenue = createAsyncThunk(
  'venue/getVenue',
  async (options: GetVenuOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, VenueAPIModel>({
        url: getApiUrlForId(VenueEndpoints.GetVenueByIdOrSubdomain, options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const patchVenue = createAsyncThunk(
  'venue/patchVenue',
  async (options: PatchVenueOptions, { rejectWithValue }) => {
    return await httpClient.put<PatchVenue, VenueAPIModel>({
      url: getApiUrlForId(VenueEndpoints.PatchVenue, options.id),
      payload: options.update,
      requiresToken: true,
    });
  },
);

export const getProperties = createAsyncThunk(
  'venue/getProperties',
  async (_options: GetVenueProperties, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, VenueProperties>({
        url: getApiUrlForId(VenueEndpoints.GetVenueProperties, _options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const patchVenueProperties = createAsyncThunk(
  'venue/patchVenuePrperties',
  async ({ id, update, alert = true }: PatchVenuePropertiesOptions, { dispatch }) => {
    await httpClient.post<PatchVenueProperties, PatchVenuePropertiesOptions>({
      url: getApiUrlForId(VenueEndpoints.PatchVenueProperties, id),
      payload: update,
      requiresToken: true,
    });

    return update;
  },
);

export const getServiceAgreement = createAsyncThunk(
  'venue/getServiceAgreement',
  async (options: GetServiceAgreementOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, GetServiceAgreementResponse>({
        url: getApiUrlForId(VenueEndpoints.GetServiceAgreement, options.id),
        requiresToken: true,
        isBlob: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const postServiceAgreement = createAsyncThunk(
  'venue/postServiceAgreement',
  async (options: PostServiceAgreementOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        { file: PostServiceAgreementOptions['file'] },
        GetServiceAgreementResponse
      >({
        url: getApiUrlForId(VenueEndpoints.UploadServiceAgreement, options.id),
        requiresToken: false,

        payload: {
          file: options.file,
        },
        contentType: 'multipart/form-data',
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deactivateVenue = createAsyncThunk(
  'venue/deactivate',
  async (venueId: string, { rejectWithValue }) => {
    try {
      return await httpClient.post<undefined, VenueAPIModel>({
        url: getApiUrlForId(VenueEndpoints.Deactivate, venueId),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const reactivateVenue = createAsyncThunk(
  'venue/reactivate',
  async (venueId: string, { rejectWithValue }) => {
    try {
      return await httpClient.post<undefined, VenueAPIModel>({
        url: getApiUrlForId(VenueEndpoints.Reactivate, venueId),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const selectedVenuCheck = (payload: VenuesListDTO[], selectedVenueId: string | null) => {
  //VenuesListDTO[]
  // check if payload is not empty
  // if selectedVenuId is not empty and maybe pulled from the localstorage
  // and might not belong to the currently logged in user.
  if (payload.length) {
    if (selectedVenueId) {
      // check if selectedVenuId is included in the venueslist for the logged in user
      if (payload.map((item) => item.venueId).includes(selectedVenueId)) {
        return selectedVenueId;
      } else {
        // if not return the first venue of the array
        return payload[0].venueId;
      }
    } else {
      // if no venueId in the store return first venuId return in the payload
      return payload[0].venueId;
    }
  } else {
    // if payload has no element, return null
    return null;
  }
};

// TODO: simplify loading and error cases
const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    reset: () => logoutState,
    setSelectedVenue: (state, { payload }) => {
      localStorage.setItem('selectedVenue', payload.id);
      state.selectedVenueId = payload.id;
    },
    setIsWebAppAvailable: (state, { payload }) => {
      state.isWebAppAvailable = payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(patchVenue.pending, (state) => {
      state.venue.isLoading = true;
    });
    reducersBuilder.addCase(patchVenue.fulfilled, (state, { payload }) => {
      state.venue.logo = payload.logo;
      state.venue.name = payload.name;
      state.venue.websiteUrl = payload.websiteUrl;
      state.venue.isLoading = false;
    });
    reducersBuilder.addCase(getVenue.rejected, (state) => {
      state.venue.error = true;
      state.venue.isLoading = false;
    });
    reducersBuilder.addCase(getVenue.pending, (state) => {
      state.venue.isLoading = true;
      state.venue.error = false;
    });
    reducersBuilder.addCase(getVenue.fulfilled, (state, { payload }) => {
      state.venue.isLoading = false;
      state.venue.error = false;
      state.venue = {
        ...payload,
        isLoading: false,
        error: false,
        lastUpdated: new Date().toISOString(),
      };
    });
    //getAccountById
    reducersBuilder.addCase(getAccountById.rejected, (state) => {
      state.venue.error = true;
      state.venue.isLoading = false;
    });
    reducersBuilder.addCase(getAccountById.pending, (state) => {
      state.venue.isLoading = true;
      state.venue.error = false;
    });
    reducersBuilder.addCase(getAccountById.fulfilled, (state, { payload }) => {
      state.venue.isLoading = false;
      state.venue.error = false;
      state.venue = {
        ...payload,
        isLoading: false,
        error: false,
        lastUpdated: new Date().toISOString(),
      };
    });
    reducersBuilder.addCase(getProperties.rejected, (state) => {
      state.venueAttributes.error = true;
      state.venueAttributes.isLoading = false;
    });
    reducersBuilder.addCase(getProperties.pending, (state) => {
      state.venueAttributes.isLoading = true;
      state.venueAttributes.error = false;
    });
    reducersBuilder.addCase(getProperties.fulfilled, (state, { payload }) => {
      state.venueAttributes.isLoading = false;
      state.venueAttributes.error = false;
      state.venueAttributes.lastUpdated = new Date().toISOString();
      state.venueAttributes.properties = payload;
    });
    reducersBuilder.addCase(getServiceAgreement.rejected, (state) => {
      state.serviceAgreement.error = true;
      state.serviceAgreement.isLoading = false;
      state.serviceAgreement.value = '';
    });
    reducersBuilder.addCase(getServiceAgreement.pending, (state) => {
      state.serviceAgreement.isLoading = true;
      state.serviceAgreement.error = false;
    });
    reducersBuilder.addCase(getServiceAgreement.fulfilled, (state, { payload }) => {
      state.serviceAgreement.isLoading = false;
      state.serviceAgreement.error = false;
      state.serviceAgreement.value = payload;
    });
    reducersBuilder.addCase(postServiceAgreement.rejected, (state) => {
      state.serviceAgreement.error = true;
      state.serviceAgreement.isLoading = false;
      state.serviceAgreement.value = '';
    });
    reducersBuilder.addCase(postServiceAgreement.pending, (state) => {
      state.serviceAgreement.isLoading = true;
      state.serviceAgreement.error = false;
    });
    reducersBuilder.addCase(postServiceAgreement.fulfilled, (state, { payload }) => {
      state.serviceAgreement.isLoading = false;
      state.serviceAgreement.error = false;
      state.serviceAgreement.value = payload;
    });
    reducersBuilder.addCase(patchVenueProperties.rejected, (state) => {
      state.venueAttributes.error = true;
      state.venueAttributes.isLoading = false;
    });
    reducersBuilder.addCase(patchVenueProperties.pending, (state) => {
      state.venueAttributes.isLoading = true;
      state.venueAttributes.error = false;
    });
    reducersBuilder.addCase(patchVenueProperties.fulfilled, (state, { payload }) => {
      state.venueAttributes.isLoading = false;
      state.venueAttributes.error = false;

      if (state.venueAttributes.properties) {
        state.venueAttributes.properties = {
          ...state.venueAttributes.properties,
          [payload.name]: payload.value,
        };
      }
    });
    reducersBuilder.addCase(getVenuesByAccountManagerId.pending, (state) => {
      state.shouldLogout = false;
    });
    reducersBuilder.addCase(getVenuesByAccountManagerId.rejected, (state) => {
      state.shouldLogout = true;
    });
    reducersBuilder.addCase(getVenuesByAccountManagerId.fulfilled, (state, { payload }) => {
      const items = payload.filter((item) => item.isActive);
      state.venuesList = items;
      state.selectedVenueId = selectedVenuCheck(
        payload.filter((item) => item.isActive),
        state.selectedVenueId,
      );
      state.shouldLogout = !(items && items.length);
    });
    reducersBuilder.addCase(createVenue.fulfilled, (state, { payload }) => {
      state.venue = payload;
      state.selectedVenueId = payload.id;
    });
    reducersBuilder.addCase(createVenue.pending, (state) => {
      state.venue.isLoading = true;
      state.venue.error = false;
    });
    reducersBuilder.addCase(getDMOList.fulfilled, (state, { payload }) => {
      state.DMOList.items = payload;
      state.DMOList.isLoading = false;
    });
    reducersBuilder.addCase(getDMOList.pending, (state) => {
      state.DMOList.isLoading = true;
    });
    reducersBuilder.addCase(getDMOList.rejected, (state) => {
      state.DMOList.isLoading = false;
      state.DMOList.items = [];
    });
    //getDMOList

    reducersBuilder.addCase(deactivateVenue.rejected, (state) => {
      state.venue.error = true;
      state.venue.isLoading = false;
    });
    reducersBuilder.addCase(deactivateVenue.pending, (state) => {
      state.venue.isLoading = true;
      state.venue.error = false;
    });
    reducersBuilder.addCase(deactivateVenue.fulfilled, (state, { payload }) => {
      state.venueAttributes.isLoading = false;
      state.venueAttributes.error = false;

      state.venue = {
        ...payload,
        isLoading: false,
        error: false,
        lastUpdated: new Date().toISOString(),
      };
    });

    reducersBuilder.addCase(reactivateVenue.rejected, (state) => {
      state.venue.error = true;
      state.venue.isLoading = false;
    });
    reducersBuilder.addCase(reactivateVenue.pending, (state) => {
      state.venue.isLoading = true;
      state.venue.error = false;
    });
    reducersBuilder.addCase(reactivateVenue.fulfilled, (state, { payload }) => {
      state.venueAttributes.isLoading = false;
      state.venueAttributes.error = false;

      state.venue = {
        ...payload,
        isLoading: false,
        error: false,
        lastUpdated: new Date().toISOString(),
      };
    });
  },
});

export const { reset, setSelectedVenue, setIsWebAppAvailable } = venueSlice.actions;
export default venueSlice.reducer;
