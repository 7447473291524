import { Box, Typography, useTheme } from '@mui/material';
import { PricingPlanModel } from '../../../api/models/pricingPlans';
import {
  SelectableTile,
  SelectableTileProps,
} from '../../../common/components/SelectableTile/SelectableTile';
// import { CopyLinkIcon } from '../../../common/assets/newDesign/CopyLinkIcon';
import { TooltipInfo } from '../../../common/components/tooltipInfo/TooltipInfo';
import { useState } from 'react';
import { CircularCheckMarkIcon } from '../../../common/assets/newDesign/CircularCheckMark';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  paragraph: {
    // width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '40px',
    padding: '10px 16px',
    whiteSpace: 'noWrap',
    fontSize: '12px',
    backgroundColor: '#4B42E5',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#ABA6FF',
      color: '#344054',
    },
  },
}));

interface PriceContainerProps {
  bgcolor?: string;
  price: string | undefined;
  bottomText: string;
}

export enum planTileStrings {
  copyButtonString = 'Copy Creation Link',
  linkCopied = 'Creation Link Copied',
}

const PriceContainer: React.FC<PriceContainerProps> = ({ bgcolor, price, bottomText }) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" bgcolor={bgcolor} p="10px" borderRadius="10px">
      <Typography color={theme.palette.primary.main} fontSize="32px" fontWeight="700">
        {typeof price !== 'undefined' ? `${price}` : '-'}
      </Typography>
      <Typography
        color={theme.palette.common.gray}
        sx={{
          textTransform: 'capitalize',
        }}
      >
        {bottomText}
      </Typography>
    </Box>
  );
};

interface PlanTileProps extends SelectableTileProps {
  name: PricingPlanModel['name'];
  monthlyFee?: PricingPlanModel['subscriptionFee'];
  billingPeriod?: PricingPlanModel['billingPeriod'];
  setupFee?: PricingPlanModel['setupFee'];
  promoCode?: PricingPlanModel['code'];
  showCopyIcon?: boolean;
  handleCopyToClipboard?: () => void;
}

const priceWithCommas = (price?: string) => {
  if (!price) return price;
  // https://stackoverflow.com/a/2901298/7686245
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const PlanTile: React.FC<PlanTileProps> = ({
  name,
  monthlyFee,
  setupFee,
  promoCode,
  billingPeriod,
  showCopyIcon = false,
  handleCopyToClipboard,
  ...selectableTileProps
}) => {
  const theme = useTheme();
  const classes = useStyles();
  // const [tooltipText, setTootlTipText] = useState('Copy Link');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLinkClick = (e: any) => {
    // e.stopPropagation();
    handleCopyToClipboard?.();
    setIsCopied(true);
    // setTootlTipText('Link Copied');
    setTimeout(() => {
      setIsCopied(false);
      // setTootlTipText('Copy Link');
    }, 2000);
  };

  return (
    <SelectableTile minWidth="290px" height="236px" padding="24px" {...selectableTileProps}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems="flex-start" width="100%">
        <Typography
          fontWeight="700"
          fontSize="20px"
          color={theme.palette.common.deepDark}
          mb="20px"
          textAlign="center"
          width="100%"
        >
          {name.toUpperCase()}
        </Typography>
      </Box>

      <Box display="flex" width="100%" justifyContent="space-between" mb="20px" zIndex={2000}>
        <PriceContainer price={priceWithCommas(setupFee)} bottomText="Setup fee" bgcolor="white" />
        <PriceContainer
          bgcolor={theme.palette.primary.light}
          price={priceWithCommas(monthlyFee)}
          bottomText={billingPeriod || ''}
        />
      </Box>
      <Box display="flex" alignItems="center" gap="16px" justifyContent="center" width="100%">
        {/* <Box
          bgcolor="#F5F4FF"
          borderRadius="100%"
          width="16px"
          height="16px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        /> */}
        {showCopyIcon && (
          <TooltipInfo title={''}>
            <span onClick={handleCopyLinkClick} style={{ width: '157px' }}>
              {isCopied ? (
                <p
                  className={classes.paragraph}
                  style={{
                    backgroundColor: '#039855',
                    color: 'white',
                  }}
                >
                  {planTileStrings.linkCopied}
                  <CircularCheckMarkIcon />
                </p>
              ) : (
                <p className={classes.paragraph}>
                  {planTileStrings.copyButtonString}
                  {/* <CopyLinkIcon /> */}
                </p>
              )}
            </span>
          </TooltipInfo>
        )}
        {/* <Typography color={theme.palette.common.gray}>Promo Code: {promoCode ?? '-'}</Typography> */}
      </Box>
    </SelectableTile>
  );
};
