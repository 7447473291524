import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    minHeight: '600px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    marginLeft: '90px',
    marginBottom: '2px',
    color: theme.palette.common.darkBlue,
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  infoText: {
    fontFamily: 'Poppins',
    color: '#8B89A0',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '15px',
    textAlign: 'left',
    userSelect: 'none',
    marginTop: '5px',
    marginBottom: '20px',
  },
  tooltipIcon: {
    marginTop: '6px',
  },
  animationInfoWrapper: {
    background: '#F9FAFB',
    borderRadius: '16px',
    padding: '14px 10px 14px 10px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  description: {
    fontSize: '16px',
    lineHeight: '28px',
    maxWidth: '100%',
    marginLeft: '90px',
    marginBottom: '32px',
    color: theme.palette.common.gray600,
  },
  divider: {
    borderBottom: '1px solid #DCDBE4',
    marginBottom: '25px',
  },
  accordionWrapper: {
    padding: '0 80px 0 40px',
  },
}));
