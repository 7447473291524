import { Box } from '@mui/material';
import React from 'react';
import { useIsTBAccount } from '../../../../../hooks/common';
import { useClientType } from '../../../../../services/hooks/useClientType';
import { useTypedSelector } from '../../../../../store';
import { isUWMClient } from '../../../../../utils/isUWMClient';
import { SocialVenuLogo } from '../../../../assets/newDesign/Sidebar/SocialVenuLogo';
import { BRAND_360_LOGO } from '../../../../constants/constants';
import { useAccountsSidebar } from '../../useAccountsSidebar';
import { useSidebarBrandLogoStyles } from './SidebarBrandLogo.styles';

export const SidebarBrandLogo: React.FC = () => {
  const { selectedVenueId: selectedVenue } = useTypedSelector((state) => state.venue);
  const isTBAccount = useIsTBAccount();
  const { isTrubluClient } = useClientType();
  const classes = useSidebarBrandLogoStyles();

  const { isSidebarOpen } = useAccountsSidebar();

  return (
    <>
      {selectedVenue && (
        <div className={classes.logoWrapper}>
          {isUWMClient() ? (
            <Box mb="20px">
              <img
                className={classes.brandLogoUwm}
                src={BRAND_360_LOGO}
                alt="distributor-logo"
                style={{
                  width: isSidebarOpen ? 'auto' : '36px',
                }}
              />
            </Box>
          ) : isTBAccount || isTrubluClient ? (
            <Box
              className={classes.brandLogoSv}
              style={{
                width: isSidebarOpen ? 'auto' : '36px',
              }}
            >
              <SocialVenuLogo />
            </Box>
          ) : (
            <Box
              className={classes.brandLogoSv}
              style={{
                width: isSidebarOpen ? 'auto' : '36px',
              }}
            >
              <SocialVenuLogo />
            </Box>
          )}
        </div>
      )}
    </>
  );
};
