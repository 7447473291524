import { Grid } from '@mui/material';
import React from 'react';
import { ITab } from '../Carousel/Carousel.helper';
import { Tab } from './Tab/Tab';
import { useClasses } from './Tab/Tabs.helper';

interface TabsProps {
  items?: ITab[];
  onTabChange: (slide: number) => void;
  activeTabIndex: number;
  tabWidth?: string;
}

export const Tabs: React.FC<TabsProps> = ({
  items,
  onTabChange,
  activeTabIndex,
  tabWidth = '100%',
}) => {
  const classes = useClasses();

  return (
    <Grid className={classes.wrapper} container>
      {items &&
        items.map((tab, i) => (
          <Grid key={i} className={classes.tabWrapper} item>
            <Tab
              tab={tab}
              onClick={() => {
                onTabChange(i);
              }}
              isActive={activeTabIndex === i}
              tabWidth={tabWidth}
            />
          </Grid>
        ))}
    </Grid>
  );
};
