import * as Yup from 'yup';

import { ErrorsStrings, TeamSettingsStrings } from '../../../common/localization/en';
import { emailValidationSchema } from '../../validation/commonValidation';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
const required = ErrorsStrings.FieldIsRequired;

export const getValidationSchemaInviteTeamMember = (countryCode: CountryCode) =>
  Yup.object().shape({
    name: Yup.string()
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' First Name')
      .required(required)
      .trim(),
    lastName: Yup.string()
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' Last Name')
      .required(required)
      .trim(),
    email: emailValidationSchema.required(TeamSettingsStrings.ErrorEmailMessage),
    phone: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', ErrorsStrings.PhoneIsNotValid, (value) =>
        value ? isValidPhoneNumber(value, countryCode) : false,
      ),
  });
