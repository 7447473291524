import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { ErrorsStrings } from '../../localization/en';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    padding: '30px',
    background: theme.palette.common.pageBackground,
  },
  profileSection: {
    padding: '30px',
    borderRadius: '15px',
    background: theme.palette.common.white,
  },
  topbap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '30px',
    letterSpasing: '0.02em',
  },
  top__divider: {
    marginTop: '22px',
    marginLeft: '-30px',
    width: 'calc(100% + 60px)',
  },
  profileRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    paddingTop: '15px',
    paddingBottom: '15px',
    borderBottom: '1px solid #DCDBE4',
  },
  profileRowTitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '27px',
    letterSpasing: '0.02em',
  },
  profileRowSubTitle: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpasing: '0.02em',
    color: '#8B89A0',
  },
  textfield: {
    minWidth: '420px',

    '& div': {
      border: 0,
    },
  },
  cellField: {
    '& div': {
      '& input': {
        border: '1px solid #cacae4',

        '&:hover': {
          borderColor: 'black',
        },
        '&:focus': {
          border: '2px solid #5242EA',
        },
      },
    },
  },
  cellWrapper: {
    paddingTop: '30px',
  },
  cellRowWrapper: {
    marginTop: 0,
    paddingTop: 0,
  },
  title: {
    marginBottom: '30px',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '40px',
    letterSpasing: '0.02em',
  },
  label: {
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpasing: '0.02em',
    color: '#8B89A0',
  },
}));

export const validationSchema = (countryCode: CountryCode) =>
  Yup.object().shape({
    firstName: Yup.string().required(ErrorsStrings.FieldIsRequired),
    lastName: Yup.string().required(ErrorsStrings.FieldIsRequired),
    cell: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', ErrorsStrings.PhoneIsNotValid, (value) =>
        value ? isValidPhoneNumber(value, countryCode) : false,
      ),
    /*  cell: Yup.string().required(ErrorsStrings.Required),
    email: Yup.string().email().required(), */
  });

export const validationSchemaPassword = () =>
  Yup.object().shape({
    password: Yup.string().required(ErrorsStrings.FieldIsRequired),
    newPassword: Yup.string()
      .min(6, ErrorsStrings.Minlength6characters)
      .required(ErrorsStrings.FieldIsRequired),
    confirmPassword: Yup.string()
      .min(6, ErrorsStrings.Minlength6characters)
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required(ErrorsStrings.FieldIsRequired),
  });
