import { FC, useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Grid, Typography, useTheme } from '@mui/material';
import { useStyles } from './NewCampaign.styles';
import { NewCampaignTopBar } from '../../common/components/NewCampaignTopBar/NewCampaignTopBar';
import {
  CampaignInvitesStrings,
  CommonStrings,
  ErrorsStrings,
  NewCampaignStrings,
  RewardStrings,
} from '../../common/localization/en';
import { TextFieldComponent } from '../../common/components/TextField/TextField';
import { CustomizationAccordion } from '../../common/components/CustomizationAccordion/CustomizationAccordion';
import {
  getAccordionItems,
  isEditRewardRoutes,
  rewardRoutes,
  VisitedRoutes,
} from './NewCampaign.helper';
import { WebsiteRoutes } from '../../common/constants/routes';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  resetNewCampaign,
  setCampaignName,
  setRewardName,
  setSMSMethod,
  setSmsInviteMessage,
  setSmsRewardMessage,
  setIsPreferred,
  setRequireEmail,
  visitAccordion,
  setCtaText,
  setCtaUrl,
  setValue,
  setCampaignType,
} from '../../store/slices/createCampaignSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { REWARD_CAMPAIGN_MAX_LENGTH } from '../../common/constants/defaults';
import { getError, getIsNullReward, isEmailValid } from '../../services/utilities';
import { LAYOUT_CONTAINER_CLASSNAME } from '../../common/constants/constants';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { NewCampaignEvents } from '../../common/constants/events/newCampaignEvents';
import { getMe } from '../../store/slices/meSlice';
import { RewardFulfillmentMethod } from '../../api/models/rewardFulfillment';
import { useSetDefaultSMSTemplates } from './CustomizeSMS/DefaultSMSTemplates/useSetDefaultSMSTemplates';
import {
  ContactMethod,
  NAME_MAX_CHAR_LIMIT,
  getRegExpForContactMethod,
} from './HowToRewardCreators/HowToRewardCreators.helpers';
import CheckboxAccordion from '../../common/components/CustomizationAccordion/CheckboxAccordion/CheckboxAccordion';
import { InitialPreview } from './InitialPreview/InitialPreview';
import { Toggle } from '../../common/components/toggle/CustomToggle';
import { ConfirmPrimaryModal } from '../../common/components/ConfirmPrimaryModal/ConfirmPrimaryModal';
import { useHistory } from 'react-router-dom';
import { ctaValidationSchema } from './CallToAction/CallToAction';
import { isUWMClient } from '../../utils/isUWMClient';
import Select, { SelectOption } from '../../common/components/Select/Select';
import clsx from 'clsx';
import { useClientType } from '../../services/hooks/useClientType';
import { CampaignTypes } from '../../api/models/campaigns';

export enum RewardTypeOptions {
  InstantReward = 'INSTANT_REWARD',
  FutureSavings = 'FUTURE_SAVINGS',
  RaffleEntry = 'RAFFLE_ENTRY',
  PublicRecognition = 'PUBLIC_RECOGNITION',
  ExclusiveAccess = 'EXCLUSIVE_ACCESS',
}

interface Props {
  isEditMode?: boolean;
  editCampaignId?: string;
}

export const NewCampaign: FC<Props> = ({ isEditMode, editCampaignId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(-1);
  const { push } = useHistory();
  const {
    campaignType,
    campaignName,
    howToRewardCreators: { contact, rewardHeader, rewardName, contactMethod },
    smsInviteMessage,
    smsRewardMessage,
    cardEnabled,
    rewardCardUrl,
    emailRequired,
    isPreferred,
    ctaButtonText,
    ctaButtonLinkUrl,
    value,
  } = useTypedSelector((state) => state.createCampaign);
  const {
    venueAttributes: { properties: venueProperties },
    venue: { websiteUrl },
  } = useTypedSelector((state) => state.venue);
  const defaultStoryValues = venueProperties?.['webapp.default-story-details'];

  const [visitedAccordions, setVisitedAccordions] = useState<VisitedRoutes>(
    isEditMode ? isEditRewardRoutes : rewardRoutes,
  );

  const [active, setActive] = useState(campaignType);

  const { id } = useTypedSelector((state) => state.me);

  const { trackEvent } = useTrackEvent();
  const { isUwmClient: isUwmAccount } = useClientType();

  const { defaultInviteMessage, defaultRewardMessage } = useSetDefaultSMSTemplates();
  const [isConfirmPrimaryModalOpen, setIsConfirmPrimaryModalOpen] = useState(false);

  const closeAllAccordions = () => {
    setActiveTabIndex(-1);
    push(
      isEditMode ? `${WebsiteRoutes.EditCampaign}/${editCampaignId}` : WebsiteRoutes.NewCampaign,
    );
  };

  useEffect(() => {
    if (!isEditMode && defaultStoryValues) {
      const { ctaBtnLinkUrl, ctaBtnText } = defaultStoryValues;
      dispatch(setCtaText(ctaBtnText));
      dispatch(setCtaUrl(ctaBtnLinkUrl));
    }
  }, [dispatch, defaultStoryValues, isEditMode]);

  useEffect(() => {
    if (isUWMClient()) {
      dispatch(setCtaUrl(websiteUrl || ''));
    }
  }, [dispatch, websiteUrl]);

  useEffect(() => {
    if (!isEditMode && !isUWMClient()) {
      dispatch(setSmsInviteMessage(defaultInviteMessage));
      dispatch(setSmsRewardMessage(defaultRewardMessage));
    }
  }, [defaultInviteMessage, defaultRewardMessage, dispatch, isEditMode]);

  useEffect(() => {
    trackEvent(NewCampaignEvents.NewRewardCampaignPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getMe({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { handleChange, touched, errors, values, setFieldTouched, setFieldValue } = useFormik({
    initialValues: {
      campaignName: campaignName,
      rewardName: rewardName,
      rewardType: value || '',
    },
    validationSchema: () =>
      Yup.object().shape({
        campaignName: Yup.string()
          .required(ErrorsStrings.FieldIsRequired)
          .max(REWARD_CAMPAIGN_MAX_LENGTH.name, CommonStrings.ExceededCharLimit)
          .matches(/\S/, CommonStrings.OnlyWhiteSpaces),
        rewardName: Yup.string()
          .required(ErrorsStrings.FieldIsRequired)
          .max(NAME_MAX_CHAR_LIMIT, CommonStrings.ExceededCharLimit)
          .matches(/\S/, CommonStrings.OnlyWhiteSpaces),
      }),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const hiddenItemTitles: string[] = [];

  const editModeHiddenItemTitles: string[] = [
    NewCampaignStrings.CampaignObjectives,
    NewCampaignStrings.VideoStyle,
    NewCampaignStrings.WhoIsInvited,
  ];
  const isNullReward = getIsNullReward(campaignType);

  if (isNullReward) {
    hiddenItemTitles.push(NewCampaignStrings.RewardCreators);
    hiddenItemTitles.push(NewCampaignStrings.CampaignObjectives);
  }

  if (isEditMode) {
    hiddenItemTitles.push(...editModeHiddenItemTitles);
  }

  const displayedItems = getAccordionItems(isNullReward).filter(
    (item) => !hiddenItemTitles.includes(item.title),
  );

  const imagePreviouslyUploaded = isEditMode && cardEnabled === false && rewardCardUrl.length > 0;

  const isContactValid =
    isNullReward ||
    (contactMethod === ContactMethod.Email
      ? isEmailValid(contact)
      : contact?.match(getRegExpForContactMethod(contactMethod as ContactMethod)));

  const isButtonSaveDisabled = () => {
    const isCampaignNameValid = !errors.campaignName;

    const isRewardFieldsValid =
      isNullReward || imagePreviouslyUploaded || (rewardHeader && rewardName);

    const areAllAccordionsVisited = isEditMode
      ? true
      : Object.entries(visitedAccordions).every(([_, value]) => value.visited);

    // const isCtaValid = ctaValidationSchema.isValidSync({
    //   ctaButtonText,
    //   ctaButtonLinkUrl,
    // });

    return (
      !isCampaignNameValid ||
      !isRewardFieldsValid ||
      !isContactValid ||
      !smsInviteMessage.trim() ||
      !smsRewardMessage.trim() ||
      !areAllAccordionsVisited
    );
  };

  const updateCampaignType = (type: CampaignTypes) => {
    const isNullReward = getIsNullReward(type);
    dispatch(
      setSMSMethod(
        isNullReward ? RewardFulfillmentMethod.MANUAL_SMS : RewardFulfillmentMethod.AUTOMATIC_SMS,
      ),
    );
    dispatch(setCampaignType(type));
    setActive(type);

    if (
      [
        RewardStrings.DefaultIncentiveCampaignName,
        RewardStrings.DefaultAwarenessCampaignName,
        RewardStrings.DefaultMonthlyGiveawayName,
        '',
      ].includes(campaignName)
    ) {
      dispatch(
        setCampaignName(
          type === CampaignTypes.CUSTOM_REWARD
            ? RewardStrings.DefaultIncentiveCampaignName
            : type === CampaignTypes.MONTHLY_GIVEAWAY
            ? RewardStrings.DefaultMonthlyGiveawayName
            : RewardStrings.DefaultAwarenessCampaignName,
        ),
      );
    }

    trackEvent(NewCampaignEvents.NewRewardCampaignPageCampaignTypeChange, {
      value: type,
    });
  };

  const getVisitedAccordions = (): VisitedRoutes => {
    const inviteSms = {
      visited: visitedAccordions['invite-sms'].visited,
      revisited: visitedAccordions['invite-sms'].revisited,
      valid: !!smsInviteMessage?.trim(),
    };

    const acknowledgementSms = {
      visited: visitedAccordions['acknowledgement-sms'].visited,
      revisited: visitedAccordions['acknowledgement-sms'].revisited,
      valid: isNullReward
        ? true
        : !!smsRewardMessage?.trim() && !!isContactValid && !!rewardHeader && !!rewardName,
    };

    const isCtaValid = ctaValidationSchema.isValidSync({
      ctaButtonLinkUrl,
      ctaButtonText,
    });

    const callToAction = {
      ...visitedAccordions['call-to-action'],
      valid: isCtaValid,
    };

    return {
      ...visitedAccordions,
      'invite-sms': inviteSms,
      'acknowledgement-sms': acknowledgementSms,
      'call-to-action': callToAction,
    };
  };

  useEffect(() => {
    return () => {
      dispatch(resetNewCampaign());
    };
  }, [dispatch]);

  const togglesMap = [
    {
      id: 'set-primary-toggle',
      toggleText: CampaignInvitesStrings.SetPrimary,
      toggleDescr: CampaignInvitesStrings.SetPrimaryDescr,
      event: NewCampaignEvents.NewRewardCampaignPageCampaignUsersSetPreferred,
      value: isPreferred,
      showInEditMode: false,
      setFunc: (isPreferred: boolean) => {
        dispatch(setIsPreferred(isPreferred));
        if (isPreferred) {
          setIsConfirmPrimaryModalOpen(true);
        }
      },
    },
    {
      id: 'require-email-toggle',
      toggleText: CampaignInvitesStrings.CaptureEmail,
      toggleDescr: CampaignInvitesStrings.CaptureEmailDescr,
      event: NewCampaignEvents.NewRewardCampaignPageRequireEmail,
      value: emailRequired,
      showInEditMode: true,
      setFunc: (isRequired: boolean) => {
        dispatch(setRequireEmail(isRequired));
      },
    },
  ];

  const rewardValueOptions: SelectOption<RewardTypeOptions>[] = [
    {
      value: RewardTypeOptions.InstantReward,
      name: (
        <>
          <strong>{NewCampaignStrings.InstantReward}</strong>{' '}
          <i>{NewCampaignStrings.InstantRewardInfo}</i>
        </>
      ),
    },
    {
      value: RewardTypeOptions.FutureSavings,
      name: (
        <>
          <strong>{NewCampaignStrings.FutureSavings}</strong>{' '}
          <i>{NewCampaignStrings.FutureSavingsInfo}</i>
        </>
      ),
    },
    {
      value: RewardTypeOptions.RaffleEntry,
      name: (
        <>
          <strong>{NewCampaignStrings.RaffleEntry}</strong>{' '}
          <i>{NewCampaignStrings.RaffleEntryInfo}</i>
        </>
      ),
    },
    {
      value: RewardTypeOptions.PublicRecognition,
      name: (
        <>
          <strong>{NewCampaignStrings.PublicRecognition}</strong>{' '}
          <i>{NewCampaignStrings.PublicRecognitionInfo}</i>
        </>
      ),
    },
    {
      value: RewardTypeOptions.ExclusiveAccess,
      name: (
        <>
          <strong>{NewCampaignStrings.ExclusiveAccess}</strong>{' '}
          <i>{NewCampaignStrings.ExclusiveAccessInfo}</i>
        </>
      ),
    },
  ];
  const isActiveCampaignType = (type: CampaignTypes) => {
    return type === campaignType;
  };
  return (
    <Box className={classes.container}>
      <ConfirmPrimaryModal
        isOpen={isConfirmPrimaryModalOpen}
        onClose={() => {
          setIsConfirmPrimaryModalOpen(false);
        }}
        onCancel={() => {
          dispatch(setIsPreferred(false));
        }}
      />
      <NewCampaignTopBar
        btnSaveDisabled={isButtonSaveDisabled()}
        handleTrackEvent={trackEvent}
        isEditMode={isEditMode}
        editCampaignId={editCampaignId}
      />
      <Grid className={`${classes.layout} ${LAYOUT_CONTAINER_CLASSNAME}`}>
        <Grid className={classes.leftSide} xs={4.3} item>
          <Box className={classes.topWrapper}>
            {!isEditMode && (
              <Box className={classes.inputWrapper}>
                <Typography className={classes.nameTitle}>
                  {NewCampaignStrings.NameCampaign}
                </Typography>
                <Box>
                  <TextFieldComponent
                    displayLabel={false}
                    className={classes.nameInput}
                    name="campaignName"
                    value={values.campaignName}
                    errorText={getError('campaignName', touched, errors)}
                    onChange={(event) => {
                      handleChange(event);
                      trackEvent(NewCampaignEvents.NewRewardCampaignNameTyped, {
                        value: event.target.value,
                      });
                      dispatch(setCampaignName(event.target.value));
                    }}
                    setFieldTouched={setFieldTouched}
                    onBlur={(event) => {
                      handleChange(event);
                    }}
                    characterLimit={REWARD_CAMPAIGN_MAX_LENGTH.name}
                    hideCharLimit
                    id="campaign-name-input"
                  />
                </Box>
              </Box>
            )}
            {!isEditMode && (
              <>
                <Box>
                  <Typography className={classes.descr}>
                    {NewCampaignStrings.Description}
                  </Typography>
                </Box>
                <Box onClick={closeAllAccordions} mt={'18px'}>
                  <ButtonGroup>
                    <Button
                      className={classes.rewardOptionButton}
                      sx={{
                        border: isActiveCampaignType(CampaignTypes.CUSTOM_REWARD)
                          ? `3px solid ${theme.palette.primary.main} !important`
                          : '3px solid #8B89A0',
                        borderRightColor: isActiveCampaignType(CampaignTypes.CUSTOM_REWARD)
                          ? `${theme.palette.primary.main} !important`
                          : 'transparent',
                        borderRadius: '50px 0 0 50px',
                        background: isActiveCampaignType(CampaignTypes.CUSTOM_REWARD)
                          ? '#F9F5FF'
                          : '#FFF',
                        color: isActiveCampaignType(CampaignTypes.CUSTOM_REWARD)
                          ? theme.palette.primary.main
                          : '#8B89A0',
                        zIndex: isActiveCampaignType(CampaignTypes.CUSTOM_REWARD) ? 1 : 0,
                      }}
                      onClick={() => {
                        updateCampaignType(CampaignTypes.CUSTOM_REWARD);
                      }}
                    >
                      {NewCampaignStrings.Custom}
                    </Button>
                    <Button
                      className={classes.rewardOptionButton}
                      sx={{
                        border: isActiveCampaignType(CampaignTypes.MONTHLY_GIVEAWAY)
                          ? `3px solid ${theme.palette.primary.main} !important`
                          : '3px solid #8B89A0',
                        borderRightColor: isActiveCampaignType(CampaignTypes.MONTHLY_GIVEAWAY)
                          ? `${theme.palette.primary.main} !important`
                          : '3px solid #8B89A0',
                        borderRadius: '0 50px 50px 0',
                        background: isActiveCampaignType(CampaignTypes.MONTHLY_GIVEAWAY)
                          ? '#F9F5FF'
                          : '#FFF',
                        color: isActiveCampaignType(CampaignTypes.MONTHLY_GIVEAWAY)
                          ? theme.palette.primary.main
                          : '#8B89A0',
                        zIndex: isActiveCampaignType(CampaignTypes.MONTHLY_GIVEAWAY) ? 1 : 0,
                      }}
                      onClick={() => {
                        updateCampaignType(CampaignTypes.MONTHLY_GIVEAWAY);
                      }}
                    >
                      {NewCampaignStrings.MonthlyGiveaway}
                    </Button>
                    {isUwmAccount && (
                      <Button
                        className={classes.rewardOptionButton}
                        sx={{
                          border: isActiveCampaignType(CampaignTypes.NO_REWARD)
                            ? `3px solid ${theme.palette.primary.main} !important`
                            : '3px solid #8B89A0',
                          borderRightColor: isActiveCampaignType(CampaignTypes.NO_REWARD)
                            ? `${theme.palette.primary.main} !important`
                            : '3px solid #8B89A0',
                          borderRadius: '0 50px 50px 0',
                          background: isActiveCampaignType(CampaignTypes.NO_REWARD)
                            ? '#F9F5FF'
                            : '#FFF',
                          color: isActiveCampaignType(CampaignTypes.NO_REWARD)
                            ? theme.palette.primary.main
                            : '#8B89A0',
                          zIndex: isActiveCampaignType(CampaignTypes.NO_REWARD) ? 1 : 0,
                        }}
                        onClick={() => {
                          updateCampaignType(CampaignTypes.NO_REWARD);
                        }}
                      >
                        {NewCampaignStrings.No}
                      </Button>
                    )}
                  </ButtonGroup>
                </Box>
              </>
            )}
            {active === CampaignTypes.MONTHLY_GIVEAWAY && (
              <Box marginTop={'20px'}>
                <Typography className={classes.MonthlyInfo}>
                  {NewCampaignStrings.MonthlyGiveawayInfo}
                  <a
                    href="https://www.socialv.io/give"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#0080FF' }}
                  >
                    {' ' + NewCampaignStrings.LearnMore}
                  </a>
                </Typography>
              </Box>
            )}
            {!isEditMode && active === CampaignTypes.CUSTOM_REWARD && (
              <Box className={classes.inputsWrapper}>
                <Typography className={classes.subTitle}>
                  {NewCampaignStrings.RewardType}
                </Typography>
                <Box>
                  <Select
                    onChange={(value) => {
                      setFieldValue('rewardType', value);
                      trackEvent(NewCampaignEvents.NewRewardCampaignRewardTypeChange, {
                        value: value,
                      });
                      dispatch(setValue(value as RewardTypeOptions));
                    }}
                    options={rewardValueOptions}
                    value={values.rewardType}
                    placeholder="Select..."
                    withPlaceholder
                    width="325px"
                    fontSize="14px"
                    height="42px"
                    withEllipsis
                  />
                </Box>
                <Typography className={classes.rewardInfo}>
                  {NewCampaignStrings.RewardTypeInfo}
                </Typography>
                <Typography className={clsx(classes.subTitle, classes.rewardName)}>
                  {NewCampaignStrings.RewardName}
                </Typography>
                <Box className={classes.rewardInputBorder}>
                  <TextFieldComponent
                    displayLabel={false}
                    className={classes.rewardInput}
                    name="rewardName"
                    value={values.rewardName}
                    errorText={getError('rewardName', touched, errors)}
                    onChange={(event) => {
                      handleChange(event);
                      trackEvent(NewCampaignEvents.NewRewardCampaignRewardNameTyped, {
                        value: event.target.value,
                      });
                      dispatch(setRewardName(event.target.value));
                    }}
                    setFieldTouched={setFieldTouched}
                    onBlur={(event) => {
                      handleChange(event);
                    }}
                    characterLimit={NAME_MAX_CHAR_LIMIT}
                    hideCharLimit
                    id="campaign-reward-name-input"
                    placeholder={NewCampaignStrings.RewardNamePlaceholder}
                    disabled={isEditMode}
                  />
                </Box>
                <Typography className={classes.rewardInputLimit}>
                  {`${values.rewardName.length} / ${NAME_MAX_CHAR_LIMIT}`}
                </Typography>
              </Box>
            )}
          </Box>

          {isEditMode && (
            <Box className={classes.editTextWrapper}>
              <Typography className={classes.editHeader}>
                {NewCampaignStrings.EditHeader}
              </Typography>
              <Typography className={classes.editSupport}>
                {NewCampaignStrings.EditSupport}
              </Typography>
            </Box>
          )}

          <Box className={classes.divider} />
          <Box className={classes.accordionWrapper}>
            {isEditMode ? (
              <CustomizationAccordion
                accordionItems={displayedItems}
                baseRoute={
                  isEditMode
                    ? `${WebsiteRoutes.EditCampaign}/${editCampaignId}`
                    : WebsiteRoutes.NewCampaign
                }
                setActiveTabIndex={setActiveTabIndex}
                handleTrackEvent={trackEvent}
                isEditMode={isEditMode}
                setVisitedAccordions={(acc: string) => {
                  dispatch(visitAccordion(acc));
                  setVisitedAccordions((prev) => ({
                    ...prev,
                    [acc]: {
                      visited: true,
                      valid: prev[acc]?.valid,
                      revisited: prev[acc]?.visited,
                    },
                  }));
                }}
              />
            ) : (
              <CheckboxAccordion
                accordionItems={displayedItems}
                baseRoute={
                  isEditMode
                    ? `${WebsiteRoutes.EditCampaign}/${editCampaignId}`
                    : WebsiteRoutes.NewCampaign
                }
                setActiveTabIndex={setActiveTabIndex}
                handleTrackEvent={trackEvent}
                isEditMode={isEditMode}
                setVisitedAccordions={(acc: string) => {
                  dispatch(visitAccordion(acc));
                  setVisitedAccordions((prev) => {
                    return {
                      ...prev,
                      [acc]: {
                        visited: true,
                        valid: prev[acc].valid,
                        revisited: prev[acc]?.visited,
                      },
                    };
                  });
                }}
                visitedAccordions={getVisitedAccordions()}
              />
            )}
          </Box>
          {!isEditMode && (
            <Box className={classes.togglesWrapper}>
              {togglesMap
                .filter(
                  (item) => !isUWMClient() || item.toggleText !== CampaignInvitesStrings.SetPrimary,
                )
                .map(
                  (
                    { toggleText, toggleDescr, showInEditMode, value, event, setFunc, id },
                    index,
                  ) => {
                    if (isEditMode && !showInEditMode) {
                      return null;
                    }
                    const isPrimary = index <= 0;
                    return (
                      <Box
                        className={isPrimary ? classes.primaryToggleWrapper : classes.toggleWrapper}
                        style={{
                          marginTop: !isPrimary ? '20px' : '0px',
                        }}
                        onClick={closeAllAccordions}
                      >
                        <Toggle
                          onChange={(status) => {
                            setFunc(status);
                            trackEvent(event, {
                              value: status + '',
                            });
                          }}
                          value={value}
                          className={classes.toggle}
                          id={id}
                        />
                        <Box className={classes.textWrapper}>
                          <Typography
                            className={
                              isPrimary
                                ? classes.primaryLimitCampaignText
                                : classes.limitCampaignText
                            }
                          >
                            {toggleText}
                          </Typography>
                          <Typography
                            className={isPrimary ? classes.primaryFutureText : classes.futureText}
                          >
                            {toggleDescr}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  },
                )}
            </Box>
          )}
        </Grid>
        <Grid className={classes.rightSide} xs={7.7} item>
          {displayedItems[activeTabIndex]?.preview || <InitialPreview />}
        </Grid>
      </Grid>
    </Box>
  );
};
