import { useState, useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import { createVenuesListTableContent } from '../../../../services/helpers/tableMappers';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  updateSort,
  // getVenueList,
  goToSelectedPage,
  updateSize,
  updateSearch,
  getVenueListApiCall,
  getAccountsBySearchFilter,
  // getAccountsBySearchFilterAPICall,
} from '../../../../store/slices/organizationVenueSlice';
import { getProperties, getVenue, setSelectedVenue } from '../../../../store/slices/venueSlice';
import { UnprotectedRoutes, WebsiteRoutes } from '../../../../common/constants/routes';
import { defaultPagination, UserRoles } from '../../../../common/constants/constants';
import { useVenueTablesStyles } from './VenuesTable.style';
import { reset as resetAnalytics } from '../../../../store/slices/analyticsSlice';
import { reset as resetHeroes } from '../../../../store/slices/heroesSlice';
import { reset as resetStoryVideos } from '../../../../store/slices/videosSlice';
import { reset as resetCampaignsStoryVideos } from '../../../../store/slices/campaignsStoryVideosSlice';
import {
  getCampaigns,
  getPrimaryCampaign,
  reset as resetCampaigns,
} from '../../../../store/slices/campaignsSlice';
import { reset as resetRewardSMSTemplate } from '../../../../store/slices/rewardFulfillmentSlice';
import { reset as resetVenueManagers } from '../../../../store/slices/venueManagerSlice';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useVenueProperty } from '../../../../hooks/useVenueProperty';
import { VenueApiModelWithStats, VenueProperties } from '../../../../api/models/venue';
import { NoFilterResultsStrings } from '../../../../common/localization/en';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { AllAccountsEventNames } from '../../../../common/constants/events/allAccountsEvents';
import { EmptyResultsPreview } from '../../../../common/components/EmptyResultsPreview/EmptyResultsPreview';
import { isUWMClient } from '../../../../utils/isUWMClient';
import { useModal } from '../../../../hooks/useModal';
import { AccountActivationDetailsModal } from './AccountActivationDetailsModal/AccountActivationDetailsModal';
import { getPricingPlansByDistributorIds } from '../../../../store/slices/pricingPlansSlice';
import { setFilterSearch } from '../../../../store/slices/searchFilterSlice';

interface IVenuesTableProps {
  children?: JSX.Element;
}

export const VenuesTable: FC<IVenuesTableProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    isArchivedAccount,
  } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { role } = useTypedSelector((state) => state.me);

  const { trackEvent } = useTrackEvent();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const selectedVenuId = useTypedSelector((state) => state.venue.selectedVenueId);
  const { id: venueId, setupCompleted } = useTypedSelector((state) => state.venue.venue);
  const { uwmRedirectCampaign } = useTypedSelector((state) => state.campaigns);
  const { distributors, activeDistributor } = useTypedSelector((state) => state.distributors);
  const { affiliates, activeAffiliate } = useTypedSelector((state) => state.affiliates);
  const [venue, setVenue] = useState<VenueApiModelWithStats | null>(null);
  const {
    isOpen: isAccountActivationDetailsModalOpen,
    open: openAccountActivationDetailsModal,
    close: closeAccountActivationDetailsModal,
  } = useModal();

  const handleCloseAccountActivationDetailModal = () => {
    closeAccountActivationDetailsModal();
    setVenue(null);
  };

  const { activeAccountFilterAttributes, search: filterSearch } = useTypedSelector(
    (state) => state.searchFilter,
  );
  const { value: quickStartValues, isLoading: isVenuesPropertyLoading } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });
  const { isUwmClient, isTopGolfClient } = useClientType();

  const { groupId } = useTypedSelector((state) => state.OrganizationVenueSlice);

  const includeTestGroups = (!!activeAffiliate && !!activeDistributor) || isArchivedAccount;

  const isUwmTable = activeDistributor?.code === 'B360' || isUWMClient();
  const styles = useVenueTablesStyles({
    isUwmTable,
  });
  const isTestGroup = activeDistributor?.code === '_TESTSV';
  // console.log('includeTestGroups', includeTestGroups);

  const history = useHistory();
  const isSuperAdmin =
    ([UserRoles.admin_role] as string[]).includes(role) ||
    !!distributors.length ||
    !!affiliates.length;
  const searchParams = {
    size,
    sort: sort.toString(),
    filterSearch,
    page: page.toString(),
  };

  useEffect(() => {
    return () => {
      dispatch(setFilterSearch(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getPricingPlansByDistributorIds({
        distributorIds: activeDistributor ? [activeDistributor.id] : [''],
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDistributor]);

  useEffect(() => {
    if (shouldRedirect && venueId === selectedVenuId && !isVenuesPropertyLoading) {
      if (!setupCompleted) {
        history.push(`${UnprotectedRoutes.CompleteProfile}${isTestGroup ? '?t=1' : ''}`);
      } else if (quickStartValues?.isQuickStartInProgress) {
        history.push(quickStartValues?.currentRoute);
      } else {
        if (
          isUwmClient &&
          localStorage.getItem('showUwmLaunchModal') === 'true' &&
          uwmRedirectCampaign
        ) {
          history.push(`${WebsiteRoutes.RewardCampaigns}/${uwmRedirectCampaign.id}`);
        } else {
          history.push(
            isTopGolfClient ? WebsiteRoutes.DashboardHeroes : WebsiteRoutes.RewardCampaigns,
          );
        }
      }
    }
  }, [
    selectedVenuId,
    venueId,
    shouldRedirect,
    history,
    quickStartValues,
    isVenuesPropertyLoading,
    isUwmClient,
    uwmRedirectCampaign,
    isTopGolfClient,
    setupCompleted,
    isTestGroup,
  ]);
  // useEffect(() => {
  //   dispatch(
  //     getVenueList({
  //       params: {
  //         ...searchParams,
  //         search: filterSearch,
  //       },
  //       withStats: true,
  //       groupId: activeDistributor
  //         ? activeDistributor.groupId
  //         : activeAffiliate
  //         ? activeAffiliate.groupId
  //         : groupId,
  //       includeTestGroups: includeTestGroups,
  //       deactivated: isArchivedAccount,
  //     }),
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   activeDistributor,
  //   activeAffiliate,
  //   dispatch,
  //   page,
  //   size,
  //   sort,
  //   groupId,
  //   isArchivedAccount,
  //   filterSearch,
  // ]);

  useEffect(() => {
    if (activeAccountFilterAttributes) {
      dispatch(
        getAccountsBySearchFilter({
          params: {
            ...searchParams,
            search: filterSearch,
          },
          filter: activeAccountFilterAttributes,
          // withStats: true,
          groupId: activeDistributor
            ? activeDistributor.groupId
            : activeAffiliate
            ? activeAffiliate.groupId
            : groupId,
          includeTestGroups: includeTestGroups,
          deactivated: isArchivedAccount,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeAccountFilterAttributes,
    groupId,
    includeTestGroups,
    isArchivedAccount,
    // searchParams,
    filterSearch,
    activeAffiliate,
    activeDistributor,
    sort,
    page,
    size,
  ]);

  const handleOpenDashboardClick = async (venueId: string) => {
    await dispatch(
      getCampaigns({
        accountId: venueId,
        pageable: { page: 0, sort: defaultPagination.sort },
      }),
    );

    dispatch(getVenue({ id: venueId }));
    dispatch(getProperties({ id: venueId }));
    dispatch(setSelectedVenue({ id: venueId }));
    dispatch(
      getPrimaryCampaign({
        venueId,
      }),
    );

    dispatch(resetAnalytics());
    dispatch(resetHeroes());
    dispatch(resetStoryVideos());
    dispatch(resetCampaignsStoryVideos());
    dispatch(resetCampaigns());
    dispatch(resetRewardSMSTemplate());
    dispatch(resetVenueManagers());

    setShouldRedirect(true);
  };

  const contentDataTable = createVenuesListTableContent(
    handleOpenDashboardClick,
    { items },
    isSuperAdmin,
    isArchivedAccount,
    activeDistributor?.code === 'B360',
  );

  const getPredictiveSearchItems = async (search: string) => {
    const result = await getVenueListApiCall({
      params: {
        page: '0',
        size: 100,
        sort: sort.toString(),
        search,
      },

      withStats: true,
      groupId,
      includeTestGroups: includeTestGroups,
      deactivated: isArchivedAccount,
    });

    const predictiveSearchItems = result.items.map((item) => item.name || '').filter(Boolean);
    return [...new Set(predictiveSearchItems)];
  };

  const onGoToPage = (targetPage: number) => {
    trackEvent(AllAccountsEventNames.venuesTablePageChanged, {
      value: targetPage.toString(),
    });
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    trackEvent(AllAccountsEventNames.venuesTableSortClicked, {
      value: name,
    });
    dispatch(updateSort(name));
  };

  const changeSize = (size: number) => {
    trackEvent(AllAccountsEventNames.venuesTableSizeChanged);
    dispatch(updateSize(size));
  };

  const onClickRow = (venueId: string) => {
    const venue = items.filter((item) => item.id === venueId)[0];
    if (venue.setupCompleted === false) {
      openAccountActivationDetailsModal();
      setVenue(venue);
    } else {
      handleOpenDashboardClick(venueId);
    }
    trackEvent(AllAccountsEventNames.tableVenueSelected, { selectedVenueId: venueId });
  };

  const handleGoToAccount = (venueId: string) => {
    handleOpenDashboardClick(venueId);
  };

  return (
    <Box sx={{ pt: '0px' }}>
      <AccountActivationDetailsModal
        isOpen={isAccountActivationDetailsModalOpen}
        onClose={handleCloseAccountActivationDetailModal}
        onGoToAccount={handleGoToAccount}
        venue={venue}
      />
      <div className={styles.AccountsTable}>
        <CommonTable
          content={contentDataTable}
          page={convertApiPageToFrontEndPage(page)}
          sort={sort}
          totalItems={totalItems}
          totalPages={totalPages}
          isLoading={isLoading}
          showLoadingLineIndicator
          noContent={error}
          tablePadding="0"
          goToPage={onGoToPage}
          onSortHeaderClick={onSort}
          size={size}
          onSizeChange={changeSize}
          withSearchBar={false}
          searchBarProps={{
            entity: 'OrganizationVenueSlice',
            adaptiveWidth: true,
            updateSearch,
            placeholderText: 'Search account',
            getPredictiveSearchItems,
          }}
          onClickRow={onClickRow}
          isSuperAdmin={isSuperAdmin}
          emptyTableCustomPreview={
            <EmptyResultsPreview
              title={NoFilterResultsStrings.NoFilterResultsAccountsTitle}
              subtitle={NoFilterResultsStrings.NoFilterResultsSubTitle}
            />
          }
          showEmptyTableCustomPreview={filterSearch.length > 0 && items.length === 0}
        >
          {children}
        </CommonTable>
      </div>
    </Box>
  );
};
