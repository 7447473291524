import { Box, CardMedia, Typography } from '@mui/material';
import { FC, MouseEvent, useRef } from 'react';
import { IPhonePreview } from '../../../common/components/IPhonePreview/IPhonePreview';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useTypedSelector } from '../../../store';
import clsx from 'clsx';
import { useVideoControls } from '../../../common/components/VideoPlayer/PlayerControls.helper';
import { WelcomeVideoModalStrings } from '../../../common/localization/en';
import { WelcomeVideoStylesType } from '../WelcomeVideoStyles/WelcomeVideoStyles';
import { useStyles } from './WelcomeVideoIPhonePreview.styles';
import { MutedVolumeIconDark } from '../../../common/assets/newDesign/VideoControlsIcons/MutedVolumeIconDark';
import { ArrowRightIcon } from '../../../common/assets/newDesign/ArrowRightIcon';

export const defaultVideos = {
  funny:
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/v1/sv-dev-assets/welcome/57819015-981b-4fa3-8e0b-2f0e8f0f74e7.mp4',
  experiential:
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/v1/sv-dev-assets/welcome/9392ebce-5e43-47f4-b749-f8454b3a9544.mp4',
  inspirational:
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/v1/sv-dev-assets/welcome/f06a1539-19af-4446-b45d-ace95d0ffc88.mp4',
  // default:
  //   'https://res.cloudinary.com/socialvenu/video/upload/fl_attachment/ar_9:16,c_fill/v1/sv-dev-assets/welcome/667390ed-6ebf-4fb3-a4fa-c70b1716d0ad.mp4',
  default:
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill/v1/sv-dev-assets/welcome/WelcomeVid_Default.mp4',
  defaultUwm:
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill/v1/sv-dev-stories/stories/204f20b3-bb85-4b46-b3ef-f550a6e7de16.mp4',
};

interface WelcomeVideoIPhonePreviewProps {
  videoUrl?: string;
}

const WelcomeVideoIPhonePreview: FC<WelcomeVideoIPhonePreviewProps> = ({ videoUrl }) => {
  const classes = useStyles();

  const { welcomeVideoUrl, uploadedWelcomeVideoUrl, recommendedVideoStyle } = useTypedSelector(
    (state) => state.createCampaign,
  );

  const isCustomVideoOptionSelected =
    recommendedVideoStyle === WelcomeVideoStylesType.custom && uploadedWelcomeVideoUrl;

  const mediaUrl = isCustomVideoOptionSelected
    ? uploadedWelcomeVideoUrl
    : welcomeVideoUrl || defaultVideos.default;

  const refVideo = useRef<HTMLVideoElement | null>(null);

  const {
    togglePlayer,
    handleOnTimeUpdate,

    playerState,
    setPlayerState,
  } = useVideoControls(refVideo);

  const togglePlay = () => {
    togglePlayer();
  };

  return (
    <IPhonePreview width={312} height={632}>
      <Box className={clsx(classes.previewWrap)}>
        <Box className={classes.topInfo}>
          <Typography className={classes.topInfoTitle}>
            {WelcomeVideoModalStrings.LetsGetStarted}
          </Typography>
          <Typography className={classes.topInfoInfo}>
            {WelcomeVideoModalStrings.WatchShortMessage}
          </Typography>
        </Box>
        <div className={clsx(classes.playIconWrapper)} onClick={togglePlay}>
          {!(playerState.isPlaying && playerState.currentTime > 0) && (
            <Box fontSize={45} className={classes.PlayIcon}>
              <PlayArrowRoundedIcon fontSize="inherit" color="inherit" />
            </Box>
          )}
        </div>
      </Box>
      <Box className={classes.bottomWrapper}>
        <Box className={classes.buttonsWrapper}>
          <Box className={classes.muteIcon}>
            <MutedVolumeIconDark />
          </Box>
          <Box className={classes.skipWrapper}>
            {WelcomeVideoModalStrings.Skip} <ArrowRightIcon />
          </Box>
        </Box>
        <Box className={classes.navigation}>
          <Box className={clsx(classes.navigationItem, classes.navigationWelcome)}>
            {WelcomeVideoModalStrings.Welcome}
          </Box>
          <Box className={clsx(classes.navigationItem, classes.navigationRest)}>
            {WelcomeVideoModalStrings.Upload}
          </Box>
          <Box className={clsx(classes.navigationItem, classes.navigationRest)}>
            {WelcomeVideoModalStrings.Verify}
          </Box>
          <Box className={clsx(classes.navigationItem, classes.navigationRest)}>
            {WelcomeVideoModalStrings.Complete}
          </Box>
        </Box>
      </Box>
      <CardMedia
        component="video"
        ref={refVideo}
        onTimeUpdate={handleOnTimeUpdate}
        src={videoUrl || mediaUrl}
        onEnded={() => setPlayerState({ ...playerState, isPlaying: false })}
        onClick={(e: React.MouseEvent) => {
          togglePlay();
        }}
        onMouseUp={(e: MouseEvent) => e.stopPropagation()}
        className={classes.video}
      />
    </IPhonePreview>
  );
};

export default WelcomeVideoIPhonePreview;
