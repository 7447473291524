import { FC } from 'react';

type CreatorIconIconProps = {
  color?: string;
  width?: number;
  height?: number;
};
export const CreatorIcon: FC<CreatorIconIconProps> = ({ width = 28, height = 28 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 28 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.22472 25H21.7753C24.5618 25 26 23.5618 26 20.8138V5.1862C26 2.4382 24.5618 1 21.7753 1H6.22472C3.45104 1 2 2.42536 2 5.1862V20.8138C2 23.5618 3.45104 25 6.22472 25ZM14.0064 15.2536C11.5538 15.2279 9.65329 13.1862 9.64045 10.4896C9.62761 7.93419 11.5666 5.80257 14.0064 5.80257C16.4462 5.80257 18.3596 7.93419 18.3596 10.4896C18.3596 13.1862 16.4462 15.2793 14.0064 15.2536ZM5.67255 22.5602C6.71268 19.504 10.0257 17.3339 14.0064 17.3339C17.9872 17.3339 21.3002 19.504 22.3274 22.5602H5.67255Z"
          fill="#8B89A0"
        />
      </svg>
    </>
  );
};
