import { FC, useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';

import { LoginContainer, LoginForm, useLoginStyles } from '../Login.style';
import { LoginPageStrings } from '../../../common/localization/en';
import { ILoginProps } from '../Login';
import {
  ForgotPasswordFormValues,
  initialValuesForgotPasswordForm,
  validationSchemaForgotPassword,
} from '../LoginFormsHelpers';
import { ResetPasswordForm } from './ResetPasswordForm';
import { forgotPassword, validatePasswordResetToken } from '../../../store/slices/meSlice';
import { UnprotectedRoutes } from '../../../common/constants/routes';
import { useToasts } from 'react-toast-notifications';
import { useQuery } from '../../../hooks/useQuery';
import { useHandleChangeWithTracking } from '../../../services/hooks/useHandleChangeWithTracking';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

export const ForgotPasswordRequestComponent: FC<ILoginProps> = ({ setStep }) => {
  const styles = useLoginStyles();
  const query = useQuery();
  const history = useHistory();
  const { addToast } = useToasts();

  const token = query.get('token') || '';
  const name = query.get('name') || '';
  const email = (query.get('email') || '').replace(' ', '+');
  const { trackEvent } = useTrackEvent();
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const isTokenValid = async () => {
      const response = await validatePasswordResetToken(token);
      if (!response.isValid) {
        addToast(LoginPageStrings.InvalidToken, { appearance: 'error', autoDismissTimeout: 25000 });
        history.push(UnprotectedRoutes.Logout);
      }
    };
    if (token) {
      isTokenValid();
    }
  }, [token, history, addToast]);

  const handleSendResetPasswordFailure = () => {
    addToast(LoginPageStrings.ErrorOccurred, {
      appearance: 'error',
      autoDismissTimeout: 2500,
    });
    setStep('login');
    setIsloading(false);
  };

  const submitForm = async (values: ForgotPasswordFormValues) => {
    setIsloading(true);
    try {
      const requesetPasswordResetResponse = await forgotPassword(values);
      if (requesetPasswordResetResponse.success) {
        setStep('forgotPasswordSuccess');
        setTimeout(() => {
          setIsloading(false);
        }, 200);
      } else {
        handleSendResetPasswordFailure();
      }
    } catch (error) {
      handleSendResetPasswordFailure();
    }
  };

  const ForgotPasswordFormik = useFormik({
    initialValues: initialValuesForgotPasswordForm,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchemaForgotPassword,
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const { handleSubmit, values, handleChange, errors } = ForgotPasswordFormik;
  const { handleChangeWithTracking } = useHandleChangeWithTracking(handleChange);
  if (token) {
    return (
      <ResetPasswordForm setStep={setStep} token={token} name={name || ''} email={email || ''} />
    );
  }

  return (
    <>
      <LoginContainer>
        <LoginForm
          onSubmit={(submitData) => {
            handleSubmit(submitData);
            trackEvent('submit_restore_password_request', {
              value: values.email,
            });
          }}
        >
          <Grid container spacing={3} direction="row-reverse" justifyContent="space-between">
            <Grid item xs={12}>
              <Typography className={styles.Title}>{LoginPageStrings.ForgotPassword}</Typography>
            </Grid>

            <Grid item xs={12} sx={{ paddingTop: '12px !important' }}>
              <Typography className={styles.SubTitle}>
                {LoginPageStrings.ForgotPasswordSubtitle}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={styles.Caption}>{LoginPageStrings.Email}</Typography>
              <TextField
                fullWidth
                error={errors.email ? true : false}
                helperText={errors.email}
                FormHelperTextProps={{
                  style: { position: 'absolute', bottom: '-20px', left: '-14px' },
                }}
                name="email"
                value={values.email}
                id="outlined-error-helper-text"
                // label={LoginPageStrings.Email}
                variant="outlined"
                placeholder={LoginPageStrings.UserNameLabel}
                onChange={handleChangeWithTracking}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={styles.Button}
                disabled={isLoading}
              >
                {LoginPageStrings.ResetPasswordBtn}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={() => setStep('login')}
                fullWidth
                className={styles.Button}
                sx={{ color: '#475467 !important' }}
              >
                {LoginPageStrings.Cancel}
              </Button>
            </Grid>
          </Grid>
        </LoginForm>
      </LoginContainer>
    </>
  );
};
