import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { VenueProperties } from '../../../api/models/venue';
import { QuickStartTopBar } from '../../../common/components/QuickStartTopBar/QuickStartTopBar';
import { DEFAULT_BRAND_COLOR } from '../../../common/constants/defaults';
import { TwoSidePageLayout } from '../../../common/layout/TwoSidePageLayout/TwoSidePageLayout';
import { CommonStrings } from '../../../common/localization/en';
import { useVenue } from '../../../hooks/useVenue';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { useBackgroundImage } from '../../../hooks/webApp/useBackgroundImage';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { patchVenue, patchVenueProperties } from '../../../store/slices/venueSlice';
import {
  filterLogoPositions,
  uploadOverlay,
} from '../../webappSettings/VideoBrandingPlayback/VideoBrandingPlayback.helper';
import { useStyles } from '../QuickStart.styles';
import { CustomizationSection } from './CustomizationSection/CustomizationSection';
import { PreviewSection } from './PreviewSection/PreviewSection';
import { useThemeLayout } from '../../../hooks/useThemeLayout';
import { AnimationStyleOptions } from '../../../common/components/AnimationStyleSelect/AnimationStyleSelect';
import { patchIncentiveCampaign } from '../../../store/slices/campaignsSlice';

export const Personalize: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const [logo, setLogo] = useState<string>();
  const [backgroundImage, setBackgroundImage] = useState<string>();
  const [backgroundImagePreview, setBackgroundImagePreview] = useState<string>();
  const [color, setColor] = useState(DEFAULT_BRAND_COLOR);
  const [colorError, setColorError] = useState(false);
  const [animationStyle, setAnimationStyle] = useState(AnimationStyleOptions.WhiteBrandingName);
  const { items } = useTypedSelector((state) => state.campaigns);

  const primaryCampaign = items.find((item) => item.isPreferred === true);

  const [accordionSubroutes, setAccordionSubroutes] = useState<Array<string>>([]);

  const backgroundImageConfig = useBackgroundImage();

  const webAppConfig = useVenueProperty<VenueProperties['webapp.config']>({
    property: 'webapp.config',
  });

  const updatePrimaryCampaign = async () => {
    if (primaryCampaign && logo) {
      return dispatch(
        patchIncentiveCampaign({
          values: {
            imageUrl: logo,
            invitationMsgConfig: {
              body: primaryCampaign.invitationMsgConfig?.body || '',
              mediaUrl: logo,
            },
          },
          campaignId: primaryCampaign.id,
        }),
      );
    }
  };

  const qrConfig = useVenueProperty<VenueProperties['webapp.config']>({
    property: 'dashboard.QRCode.config',
  });

  const { theme } = useThemeLayout();

  const { venueId, logo: venueLogo } = useVenue({
    onVenueLoad: () => {
      const color = webAppConfig?.value?.['primary-color'] || DEFAULT_BRAND_COLOR;
      const background = backgroundImageConfig.backgroundImage ?? undefined;

      setLogo(venueLogo);
      setBackgroundImage(background);
      setColor(color);

      if (venueLogo) {
        setAnimationStyle(AnimationStyleOptions.WhiteBrandingLogo);
      }
    },
  });

  const disableNextButton = colorError;

  const saveLogoImage = async () => {
    qrConfig.update(
      {
        logo,
      },
      { patchObjectValue: true },
    );
    return dispatch(patchVenue({ id: venueId, update: { logo } }));
  };

  const saveBackgroundImage = async () => {
    if (!backgroundImage) return;
    return backgroundImageConfig.updateBackgroundImage(backgroundImage);
  };

  const saveBrandColor = async () => {
    if (!colorError) {
      qrConfig.update(
        {
          brandColor: color,
        },
        { patchObjectValue: true },
      );

      return webAppConfig.update(
        {
          'primary-color': color,
        },
        { patchObjectValue: true },
      );
    } else return;
  };

  const saveAnimationStyle = async () => {
    const lanuchScreenOptions = animationStyle.split('_');
    await dispatch(
      patchVenueProperties({
        id: venueId,
        update: {
          name: 'webapp.launch-screen',
          value: {
            cover: lanuchScreenOptions[0],
            reveal: lanuchScreenOptions[1],
            type: lanuchScreenOptions[2] ? lanuchScreenOptions[2] : logo ? 'logo' : 'name',
          },
        },
      }),
    );
  };

  const handleUpload = async () => {
    if (venueId) {
      return await uploadOverlay(
        webAppConfig.value?.['filter-overlay-url'] || '',
        logo ?? venueLogo,
        filterLogoPositions.topLeft,
        venueId,
        false,
      );
    } else {
      return Promise.resolve('');
    }
  };

  const onLogoNextClick = async () => {
    await handleUpload().then((filterUrl) => {
      webAppConfig.update(
        {
          'filter-logo-position': filterLogoPositions.topLeft,
          'filter-logo-enabled': true,
          'filter-overlay-enabled': false,
          'filter-overlay-url': '',
          'filter-url': filterUrl,
          'primary-color': color,
        },
        { patchObjectValue: true },
      );
    });
  };

  const saveDataToVenueProperties = async () => {
    await Promise.all([
      onLogoNextClick(),
      saveLogoImage(),
      updatePrimaryCampaign(),
      saveBackgroundImage(),
      saveBrandColor(),
      saveAnimationStyle(),
    ]);
    addToast(CommonStrings.OnSuccess, { appearance: 'success' });
  };

  const handleAnimationStyleChange = (value: AnimationStyleOptions) => {
    setAnimationStyle(value);
  };

  const launchScreenType = animationStyle.split('_')[2]
    ? (animationStyle.split('_')[2] as 'logo' | 'name')
    : logo
    ? 'logo'
    : 'name';

  return (
    <>
      <QuickStartTopBar
        disableNextButton={disableNextButton}
        onNextClick={saveDataToVenueProperties}
        accordionSubroutes={accordionSubroutes}
      />
      <Box className={classes.container} style={{ overflowY: 'auto' }}>
        <TwoSidePageLayout
          leftSideNode={
            <CustomizationSection
              logo={logo}
              onLogoChange={(logo) => {
                if (logo) {
                  setLogo(logo);
                }

                if (logo && (!backgroundImage || !backgroundImagePreview)) {
                  handleAnimationStyleChange(AnimationStyleOptions.WhiteBrandingLogo);
                  return;
                }
              }}
              background={backgroundImage}
              onBackgroundChange={(src: string | undefined) => {
                setBackgroundImage(src);

                const launchOptions = animationStyle.split('_');
                if (logo && src) {
                  handleAnimationStyleChange(AnimationStyleOptions.WhiteSplashLogo);
                  return;
                }

                if (!logo && !src) {
                  handleAnimationStyleChange(AnimationStyleOptions.WhiteBrandingName);
                  return;
                }

                if (launchOptions[1] === 'branding' && src) {
                  handleAnimationStyleChange(
                    animationStyle.replace('branding', 'splash') as AnimationStyleOptions,
                  );
                  return;
                }

                if (launchOptions[1] === 'splash' && !src) {
                  handleAnimationStyleChange(
                    animationStyle.replace('splash', 'branding') as AnimationStyleOptions,
                  );
                  return;
                }
              }}
              onBackgroundPreviewChange={setBackgroundImagePreview}
              color={color}
              onColorChange={setColor}
              setColorError={setColorError}
              colorError={colorError}
              onColorNextClick={saveBrandColor}
              onBackgroundNextClick={saveBackgroundImage}
              onSetAccordionSubroutes={setAccordionSubroutes}
              animationsStyle={animationStyle}
              onAnimationStyleChange={handleAnimationStyleChange}
            />
          }
          rightSideNode={
            <PreviewSection
              logoImage={logo}
              brandColor={color}
              backgroundImage={backgroundImagePreview || backgroundImage}
              theme={theme}
              selectedAnimation={animationStyle}
              launchScreenType={launchScreenType}
              handleAnimationStyleChange={handleAnimationStyleChange}
            />
          }
          rightSideTop="0"
          leftSideColumnWidths={6}
        />
      </Box>
    </>
  );
};
